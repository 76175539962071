import { messageableFactory as actionableFactory } from './ui.adapter';

export class InitializeLayoutMessage extends actionableFactory.create<
  'Initialize Layout',
  Record<string, never>
>('Initialize Layout') {}

export class UpdateShowHeaderFilterInformationMessage extends actionableFactory.create<
  'Update Show Header Filter Information Message',
  Record<string, never>
>('Update Show Header Filter Information Message') {}

export class UpdateShowFiltersPanelMessage extends actionableFactory.create<
  'Update Show Filters Panel Message',
  { show: boolean }
>('Update Show Filters Panel Message') {}

export class UpdateShowProductPanelMessage extends actionableFactory.create<
  'Update Show Product Panel Message',
  { show: boolean }
>('Update Show Product Panel Message') {}

export type LayoutUiMessages =
  | InitializeLayoutMessage
  | UpdateShowHeaderFilterInformationMessage
  | UpdateShowFiltersPanelMessage
  | UpdateShowProductPanelMessage;
