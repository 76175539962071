import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { PermissionEffects } from './+state';

@NgModule({
  imports: [EffectsModule.forFeature([PermissionEffects])],
  declarations: [],
})
export class PermissionModule {}
