import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { provideTippyConfig } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { NgrxFormsModule } from 'ngrx-forms';
import { CookieService } from 'ngx-cookie-service';

import { AuthService } from '@locumsnest/auth/src/lib/session-auth/services';
import {
  AUTH_USER_TYPE_COOKIE,
  AUTH_USER_TYPE_COOKIE_EXPECTED_VALUE,
  HOME_PAGE,
} from '@locumsnest/auth/src/lib/session-auth/services/opaque-tokens';
import { SessionAuthModule } from '@locumsnest/auth/src/lib/session-auth/session-auth.module';
import { DEFAULT_LOADING_PHRASES } from '@locumsnest/components/src/lib/atoms/spinner/opaque-tokens';
import { CoreModule, SimpleRouterStateSerializer } from '@locumsnest/core';
import { GLOBAL_ALERT_HANDLER } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/opaque-tokens';
import { FILTER_SERVICES } from '@locumsnest/core/src/lib/micro-app/opaque-tokens';

import { ACCOUNT_ROUTER_SERVICE } from '../../../../libs/auth/src/account/opaque-tokens';
import { HOME_PAGE_SERVICE } from '../../../../libs/auth/src/lib/session-auth/services/opaque-tokens';
import { RouterService } from '../../../../libs/core/src/lib/router/router.service';
import packageInfo from '../../../../package.json';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FilterUiStateModule } from './core/+state/filter-ui/filter-ui-state.module';
import {
  DEFAULT_CURRENCY,
  DEFAULT_LOCALE_ID,
  HTTP_API_CONFIGURATION,
  LOADING_PHRASES,
} from './core/constants';
import { MICRO_APP_CONFIGURATION } from './core/micro-apps.config';
import { APP_DETAILS_TOKEN } from './core/opaque-tokens';
import { LayoutService, SERVICE_PROVIDERS } from './core/services';
import { HomePageService } from './core/services/state/home-page.service';
import { PermissionModule } from './permission/permission.module';
import { PreferredPassportProfessionService } from './preferred-passport-profession/+state/preferred-passport-profession.service';
import { PreferredProfessionSpecialtyService } from './preferred-profession-specialty/+state/preferred-profession-specialty.service';
import { PreferredProfessionService } from './preferred-profession/+state/preferred-profession.service';
import { PreferredSiteService } from './preferred-site/+state/preferred-site.service';
import { PreferredSpecialtyCategoryService } from './preferred-specialty-category/+state/preferred-specialty-category.service';
import { ProfessionSpecialtyService } from './profession-specialty/+state/profession-specialty.service';
import { ProfessionService } from './profession/+state/profession.service';
import { metaReducers, reducers } from './reducers';
import { SiteService } from './site/+state/site.service';
import { SpecialtyService } from './specialty/+state/specialty.service';
import { SubSpecialtyService } from './sub-specialty/+state/sub-specialty.service';

const reduxTools = environment.reduxTools
  ? [
      StoreDevtoolsModule.instrument({
        name: 'NgRx Hospital Admin DevTools',
        logOnly: environment.production,
        serialize: { options: true },
        connectInZone: true,
      }),
    ]
  : [];

function isAuth(authService: AuthService) {
  return () => authService.isAuth();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),
    SessionAuthModule.forRoot([
      {
        provide: HOME_PAGE_SERVICE,
        useExisting: HomePageService,
      },
    ]),
    AppRoutingModule,
    NgSelectModule,
    NgrxFormsModule,

    PermissionModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    CoreModule.forRoot(HTTP_API_CONFIGURATION, MICRO_APP_CONFIGURATION),
    StoreRouterConnectingModule.forRoot({
      serializer: SimpleRouterStateSerializer,
    }),
    EffectsModule.forRoot([]),
    FilterUiStateModule,
    ...reduxTools,
  ],
  providers: [
    CookieService,
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: {
          allowHTML: true,
          theme: null,
          arrow: true,
          animation: 'scale',
          trigger: 'mouseenter',
          offset: [0, 5],
        },
        popper: {
          theme: null,
          arrow: true,
          offset: [0, 10],
          animation: 'scale',
          trigger: 'mouseenter',
          interactive: true,
        },
        hideArrow: {
          allowHTML: true,
          theme: null,
          arrow: false,
          animation: 'scale',
          trigger: 'mouseenter',
          offset: [0, 5],
        },
      },
    }),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: APP_DETAILS_TOKEN,
      useValue: { version: packageInfo.version },
    },
    {
      provide: DEFAULT_LOADING_PHRASES,
      useValue: LOADING_PHRASES,
    },
    ...SERVICE_PROVIDERS,
    {
      provide: GLOBAL_ALERT_HANDLER,
      useExisting: LayoutService,
    },
    {
      provide: ACCOUNT_ROUTER_SERVICE,
      useExisting: RouterService,
    },
    {
      provide: AUTH_USER_TYPE_COOKIE,
      useValue: 'lnusertype',
    },
    {
      provide: AUTH_USER_TYPE_COOKIE_EXPECTED_VALUE,
      useValue: 'officer',
    },
    {
      provide: HOME_PAGE,
      useValue: 'dashboard',
    },
    {
      provide: FILTER_SERVICES,
      useExisting: ProfessionService,
      multi: true,
    },
    {
      provide: FILTER_SERVICES,
      useExisting: SiteService,
      multi: true,
    },
    {
      provide: FILTER_SERVICES,
      useExisting: PreferredProfessionService,
      multi: true,
    },
    {
      provide: FILTER_SERVICES,
      useExisting: PreferredSpecialtyCategoryService,
      multi: true,
    },
    {
      provide: FILTER_SERVICES,
      useExisting: PreferredProfessionSpecialtyService,
      multi: true,
    },
    {
      provide: FILTER_SERVICES,
      useExisting: SpecialtyService,
      multi: true,
    },
    {
      provide: FILTER_SERVICES,
      useExisting: ProfessionSpecialtyService,
      multi: true,
    },
    {
      provide: FILTER_SERVICES,
      useExisting: SubSpecialtyService,
      multi: true,
    },
    {
      provide: FILTER_SERVICES,
      useExisting: PreferredSiteService,
      multi: true,
    },
    {
      provide: FILTER_SERVICES,
      useExisting: ProfessionService,
      multi: true,
    },
    {
      provide: FILTER_SERVICES,
      useExisting: PreferredPassportProfessionService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: DEFAULT_LOCALE_ID },
    { provide: DEFAULT_CURRENCY_CODE, useValue: DEFAULT_CURRENCY },
    { provide: TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AuthService],
      useFactory: isAuth,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
