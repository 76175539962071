import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { flatMap } from 'lodash-es';
import { combineLatest, concat, merge, Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';

import { SelectAllInProgressMessage } from '../../../core/+state/filter-ui/filter-ui.messages';
import { PreferredProfessionSpecialtyService } from '../../../preferred-profession-specialty/+state/preferred-profession-specialty.service';
import { PreferredSpecialtyCategoryService } from '../../../preferred-specialty-category/+state/preferred-specialty-category.service';
import { ProfessionService } from '../../../profession/+state/profession.service';
import { PreferredProfessionPersistenceService } from '../preferred-profession.persistence.service';
import { PreferredProfessionService } from '../preferred-profession.service';
import { SelectAllCompleteMessage } from './../../../core/+state/filter-ui/filter-ui.messages';
import {
  IPreferredProfessionEntity,
  IPreferredProfessionPostEntity,
} from './../../../interfaces/api/preferred-profession-entity';
import { conditionalErrorHandler } from './../preferred-profession.adapter';
import { AddOneMessage, DeleteOneMessage } from './preferred-profession.messages';
import {
  AddSelectedPreferredProfessionTempSignal,
  ClearAllPreferredProfessionsTempSignal,
  RemoveSelectedPreferredProfessionTempSignal,
  SelectAllPreferredProfessionsTempSignal,
} from './preferred-profession.signals';

@Injectable()
export class PreferredProfessionTempEffects {
  addSelectedPreferredProfessionTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddSelectedPreferredProfessionTempSignal>(
        AddSelectedPreferredProfessionTempSignal.TYPE
      ),
      mergeMap((action) => {
        const { payload } = action as AddSelectedPreferredProfessionTempSignal;

        const data: IPreferredProfessionPostEntity = {
          profession: payload.id,
        };

        return this.preferredProfessionPersistenceService
          .create<IPreferredProfessionPostEntity, IPreferredProfessionEntity>(data)
          .pipe(
            map(
              (response: IPreferredProfessionEntity) =>
                new AddOneMessage({
                  entity: response,
                })
            ),
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: (message) =>
                  `An error occurred while selected the profession: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Profession can’t be selected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Profession can’t be selected. Please try again in a few minutes',
              })
            )
          );
      })
    )
  );

  removeSelectedPreferredProfessionTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveSelectedPreferredProfessionTempSignal>(
        RemoveSelectedPreferredProfessionTempSignal.TYPE
      ),
      mergeMap((action) =>
        combineLatest([
          this.preferredProfessionService.getPreferredProfessionByProfessionId(action.payload.id),
          this.preferredProfessionService.getPreferredProfessionCategoriesByProfessionId(
            action.payload.id
          ),
        ]).pipe(first())
      ),
      mergeMap(([preferredProfession, professionSpecialtyCategoryIds]) =>
        this.preferredProfessionPersistenceService.delete(preferredProfession.id).pipe(
          mergeMap(
            () =>
              merge(
                of(new DeleteOneMessage({ id: preferredProfession.id })),
                ...flatMap(professionSpecialtyCategoryIds, (professionSpecialtyCategoryId) => [
                  this.preferredSpecialtyCategoryService.deleteSpecialtyCategory(
                    professionSpecialtyCategoryId,
                    preferredProfession.profession,
                    false
                  ),
                  this.preferredProfessionSpecialtyService.deleteSpecialtyCategorySubSpecialties(
                    professionSpecialtyCategoryId,
                    preferredProfession.profession,
                    false
                  ),
                ])
              ) as Observable<Action>
          ),
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while selected the profession: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Profession can’t be selected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Profession can’t be selected. Please try again in a few minutes',
            })
          )
        )
      )
    )
  );

  selectAllPreferredProfessionsTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectAllPreferredProfessionsTempSignal>(SelectAllPreferredProfessionsTempSignal.TYPE),
      concatLatestFrom(() => [
        this.professionService.getAllActive(),
        this.preferredProfessionService.getAllTemp(),
      ]),
      mergeMap(([_, professions, preferredProfessions]) => {
        const professionIds = professions.map((profession) => profession.id);
        const preferredProfessionIds = preferredProfessions.map(
          (profession) => profession.profession
        );

        const unSelectedProfessionIds = professionIds.filter(
          (profession) => !preferredProfessionIds.includes(profession)
        );
        const unSelectedProfessions = unSelectedProfessionIds.map((profession) => ({
          profession,
        }));
        return concat(
          of(new SelectAllInProgressMessage({})),
          this.preferredProfessionService.bulkCreate(unSelectedProfessions).pipe(
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: (message) =>
                  `An error occurred while selected the professions: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Professions can’t be selected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Professions can’t be selected. Please try again in a few minutes',
              })
            )
          ),
          of(new SelectAllCompleteMessage({}))
        );
      })
    )
  );

  clearAllPreferredProfessionsTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ClearAllPreferredProfessionsTempSignal>(ClearAllPreferredProfessionsTempSignal.TYPE),
      mergeMap(() =>
        merge(
          this.preferredSpecialtyCategoryService.clear().pipe(
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: () => `An error occurred while clearing professions`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Professions can’t be unselected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Professions can’t be unselected. Please try again in a few minutes',
              })
            )
          ),
          this.preferredProfessionSpecialtyService.clear().pipe(
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: () => `An error occurred while clearing specialties`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! specialties can’t be unselected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Professions can’t be unselected. Please try again in a few minutes',
              })
            )
          ),
          this.preferredProfessionService.clear().pipe(
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: () => `An error occurred while clearing specialties`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! specialties can’t be unselected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Professions can’t be unselected. Please try again in a few minutes',
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private preferredProfessionPersistenceService: PreferredProfessionPersistenceService,
    private preferredSpecialtyCategoryService: PreferredSpecialtyCategoryService,
    private preferredProfessionSpecialtyService: PreferredProfessionSpecialtyService,
    private preferredProfessionService: PreferredProfessionService,
    private professionService: ProfessionService
  ) {}
}
