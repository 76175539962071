import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { concat, of } from 'rxjs';
import { catchError, exhaustMap, first, map, mergeMap } from 'rxjs/operators';

import { selectFilterUiState } from '../../../core/+state/filter-ui';
import {
  IPreferredRosterEntity,
  IPreferredRosterPostEntity,
} from '../../../interfaces/api/preferred-roster-entity';
import { RosterService } from '../../../roster/+state/roster.service';
import { conditionalErrorHandler } from '../preferred-roster.adapter';
import { PreferredRosterPersistenceService } from '../preferred-roster.persistence.service';
import { PreferredRosterService } from '../preferred-roster.service';
import {
  SelectAllCompleteMessage,
  SelectAllInProgressMessage,
} from './../../../core/+state/filter-ui/filter-ui.messages';
import { AddOneMessage, DeleteOneMessage } from './preferred-roster.messages';
import {
  AddSelectedPreferredRosterTempSignal,
  ClearAllPreferredRostersForSelectedWardTempSignal,
  ClearAllPreferredRostersTempSignal,
  ClearAllUnassignedPreferredRostersTempSignal,
  RemoveSelectedPreferredRosterTempSignal,
  SelectAllPreferredRostersForSelectedWardTempSignal,
  SelectAllPreferredRostersTempSignal,
  SelectAllUnassignedPreferredRostersTempSignal,
} from './preferred-roster.signals';

@Injectable()
export class PreferredRosterTempEffects {
  addSelectedPreferredRosterTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddSelectedPreferredRosterTempSignal>(AddSelectedPreferredRosterTempSignal.TYPE),
      mergeMap((action) => {
        const { payload } = action as AddSelectedPreferredRosterTempSignal;

        const data: IPreferredRosterPostEntity = {
          roster: payload.id,
          ward: payload.wardId,
        };
        return concat(
          this.persistenceService
            .create<IPreferredRosterPostEntity, IPreferredRosterEntity>(data)
            .pipe(
              map(
                (response: IPreferredRosterEntity) =>
                  new AddOneMessage({
                    entity: response,
                  })
              ),
              catchError(
                conditionalErrorHandler({
                  errorEventMessageHandler: (message) =>
                    `An error occurred while selected the roster: ${message}`,
                  errorDetailMessageHandler: (message) =>
                    `Sorry! Roster can’t be selected. The error was: ${message}`,
                  unknownErrorMessage:
                    'Sorry! Roster can’t be selected. Please try again in a few minutes',
                })
              )
            )
        );
      })
    )
  );

  removeSelectedPreferredRosterTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveSelectedPreferredRosterTempSignal.TYPE),
      concatLatestFrom(() => this.preferredRosterService.getAllTemp()),
      mergeMap(([action, preferredRosters]) => {
        const { payload } = action as RemoveSelectedPreferredRosterTempSignal;
        const preferredRoster = preferredRosters.find(({ roster }) => roster === payload.id);
        return this.persistenceService.delete(preferredRoster.id).pipe(
          map(() => new DeleteOneMessage({ id: preferredRoster.id })),
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while unselected the roster: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Roster can’t be unselected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Roster can’t be unselected. Please try again in a few minutes',
            })
          )
        );
      })
    )
  );

  selectAllPreferredRostersTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectAllPreferredRostersTempSignal>(SelectAllPreferredRostersTempSignal.TYPE),
      concatLatestFrom(() => [
        this.rosterService.getAll(),
        this.preferredRosterService.getAllTemp(),
      ]),
      mergeMap(([_, rosters, preferredRosters]) => {
        const rosterIds = rosters.map((roster) => roster.id);
        const preferredRosterIds = preferredRosters.map((roster) => roster.roster);

        const unSelectedRosterIds = rosterIds.filter(
          (roster) => !preferredRosterIds.includes(roster)
        );
        const unSelectedRosters = unSelectedRosterIds.map((roster) => ({ roster }));

        const actions$ = this.preferredRosterService.bulkCreate(unSelectedRosters).pipe(
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while selecting the rosters: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Rosters can’t be selected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Rosters can’t be selected. Please try again in a few minutes',
            })
          )
        );
        return concat(
          of(new SelectAllInProgressMessage({})),
          actions$,
          of(new SelectAllCompleteMessage({}))
        );
      })
    )
  );

  clearAllPreferredRostersTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ClearAllPreferredRostersTempSignal>(ClearAllPreferredRostersTempSignal.TYPE),
      mergeMap((_) =>
        this.preferredRosterService.clear().pipe(
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while unselecting the rosters: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Rosters can’t be unselected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Rosters can’t be unselected. Please try again in a few minutes',
            })
          )
        )
      )
    )
  );

  selectAllUnassignedPreferredRostersTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectAllUnassignedPreferredRostersTempSignal>(
        SelectAllUnassignedPreferredRostersTempSignal.TYPE
      ),
      concatLatestFrom(() => [
        this.rosterService.getAll(),
        this.preferredRosterService.getAllTemp(),
      ]),
      mergeMap(([_, rosters, preferredRosters]) => {
        const rosterIds = rosters
          .filter((roster) => roster.ward === null)
          .map((roster) => roster.id);
        const preferredRosterIds = preferredRosters.map((roster) => roster.roster);

        const unSelectedRosterIds = rosterIds.filter(
          (roster) => !preferredRosterIds.includes(roster)
        );
        const unSelectedRosters = unSelectedRosterIds.map((roster) => ({ roster }));

        const actions$ = this.preferredRosterService.bulkCreate(unSelectedRosters).pipe(
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while selecting the rosters: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Rosters can’t be selected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Rosters can’t be selected. Please try again in a few minutes',
            })
          )
        );
        return concat(
          of(new SelectAllInProgressMessage({})),
          actions$,
          of(new SelectAllCompleteMessage({}))
        );
      })
    )
  );

  clearAllUnassignedPreferredRostersTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ClearAllUnassignedPreferredRostersTempSignal>(
        ClearAllUnassignedPreferredRostersTempSignal.TYPE
      ),
      concatLatestFrom(() => this.preferredRosterService.getTempPreferredUnassignedRosters()),
      mergeMap(([_, preferredUnassignedRosters]) =>
        this.preferredRosterService.bulkDelete(preferredUnassignedRosters.map(({ id }) => id)).pipe(
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while unselecting the rosters: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Rosters can’t be unselected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Rosters can’t be unselected. Please try again in a few minutes',
            })
          )
        )
      )
    )
  );

  selectAllForWard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectAllPreferredRostersForSelectedWardTempSignal>(
        SelectAllPreferredRostersForSelectedWardTempSignal.TYPE
      ),
      concatLatestFrom(() => this.store.pipe(select(selectFilterUiState))),
      exhaustMap(([_, filterUiState]) => {
        const wardId = filterUiState.selectedCategoryId;

        return this.preferredRosterService.getInexistentByWard(wardId).pipe(
          first(),
          mergeMap((unSelectedRosterIds) => {
            const unSelectedRosters = unSelectedRosterIds.map((roster) => ({
              roster,
              ward: wardId,
            }));
            return this.preferredRosterService.bulkCreate(unSelectedRosters).pipe(
              catchError(
                conditionalErrorHandler({
                  errorEventMessageHandler: (message) =>
                    `An error occurred while selected the rosters: ${message}`,
                  errorDetailMessageHandler: (message) =>
                    `Sorry! Rosters can’t be selected. The error was: ${message}`,
                  unknownErrorMessage:
                    'Sorry! Rosters can’t be selected. Please try again in a few minutes',
                })
              )
            );
          })
        );
      })
    )
  );

  clearAllForWard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ClearAllPreferredRostersForSelectedWardTempSignal>(
        ClearAllPreferredRostersForSelectedWardTempSignal.TYPE
      ),
      concatLatestFrom(() => this.store.pipe(select(selectFilterUiState))),
      exhaustMap(([_, filterUiState]) => {
        const wardId = filterUiState.selectedCategoryId;

        return this.preferredRosterService.getAllTempIdsByWard(wardId).pipe(
          first(),
          mergeMap((rosters) =>
            this.preferredRosterService.bulkDelete(rosters).pipe(
              catchError(
                conditionalErrorHandler({
                  errorEventMessageHandler: (message) =>
                    `An error occurred while unselected the rosters: ${message}`,
                  errorDetailMessageHandler: (message) =>
                    `Sorry! Rosters can’t be unselected. The error was: ${message}`,
                  unknownErrorMessage:
                    'Sorry! Rosters can’t be unselected. Please try again in a few minutes',
                })
              )
            )
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private persistenceService: PreferredRosterPersistenceService,
    private rosterService: RosterService,
    private preferredRosterService: PreferredRosterService
  ) {}
}
