import { combineReducers } from '@ngrx/store';

import { ILayoutUiState } from '../interfaces';
import { alertStateAdapter } from './ui.adapter';
import {
  InitializeLayoutMessage,
  LayoutUiMessages,
  UpdateShowFiltersPanelMessage,
  UpdateShowHeaderFilterInformationMessage,
  UpdateShowProductPanelMessage,
} from './ui.messages';

export const INITIAL_UI_STATE: ILayoutUiState = {
  showHeaderFilterInformation: false,
  showFiltersPanel: false,
  showProductPanel: false,
};

export function stateReducer(state: ILayoutUiState = INITIAL_UI_STATE, action: LayoutUiMessages) {
  switch (action.type) {
    case InitializeLayoutMessage.TYPE:
      return { ...INITIAL_UI_STATE, ...action.payload };
    case UpdateShowHeaderFilterInformationMessage.TYPE:
      state = {
        ...state,
        showHeaderFilterInformation: !state.showHeaderFilterInformation,
      };
      break;
    case UpdateShowFiltersPanelMessage.TYPE: {
      const { payload } = action as UpdateShowFiltersPanelMessage;

      state = {
        ...state,
        showFiltersPanel: payload.show,
      };
      break;
    }
    case UpdateShowProductPanelMessage.TYPE: {
      const { payload } = action as UpdateShowProductPanelMessage;

      state = {
        ...state,
        showProductPanel: payload.show,
      };
      break;
    }
    default:
      break;
  }

  return state;
}
const alertStateReducer = alertStateAdapter.createReducer({});

export const reducer = combineReducers({
  state: stateReducer,
  alertState: alertStateReducer,
});
