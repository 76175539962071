import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';

import { PermissionService } from './permission.service';
import { InitializeCurrentUserPermissionsSignal } from './permission.signals';

@Injectable()
export class PermissionEffects {
  initializeCurrentUserPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitializeCurrentUserPermissionsSignal.TYPE),
      mergeMap(() => this.permissionService.load())
    )
  );

  constructor(protected actions$: Actions, protected permissionService: PermissionService) {}
}
