import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { concat, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  SelectAllCompleteMessage,
  SelectAllInProgressMessage,
} from '../../../core/+state/filter-ui/filter-ui.messages';
import { SiteService } from '../../../site/+state/site.service';
import { PreferredSitePersistenceService } from '../preferred-site.persistence.service';
import { PreferredSiteService } from '../preferred-site.service';
import {
  IPreferredSiteEntity,
  IPreferredSitePostEntity,
} from './../../../interfaces/api/preferred-site-entity';
import { conditionalErrorHandler } from './../preferred-site.adapter';
import { selectAllPreferredSitesTemp } from './../preferred-site.selectors';
import { AddOneMessage, DeleteOneMessage } from './preferred-site.messages';
import {
  AddSelectedPreferredSiteTempSignal,
  ClearAllPreferredSitesTempSignal,
  RemoveSelectedPreferredSiteTempSignal,
  SelectAllPreferredSitesTempSignal,
} from './preferred-site.signals';

@Injectable()
export class PreferredSiteTempEffects {
  addSelectedPreferredSiteTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddSelectedPreferredSiteTempSignal>(AddSelectedPreferredSiteTempSignal.TYPE),
      mergeMap((action) => {
        const { payload } = action as AddSelectedPreferredSiteTempSignal;

        const data: IPreferredSitePostEntity = {
          site: payload.id,
        };

        return this.preferredSitePersistenceService
          .create<IPreferredSitePostEntity, IPreferredSiteEntity>(data)
          .pipe(
            map(
              (response: IPreferredSiteEntity) =>
                new AddOneMessage({
                  entity: response,
                })
            ),
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: (message) =>
                  `An error occurred while selected the site: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Site can’t be selected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Site can’t be selected. Please try again in a few minutes',
              })
            )
          );
      })
    )
  );

  removeSelectedPreferredSiteTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveSelectedPreferredSiteTempSignal.TYPE),
      concatLatestFrom(() => this.store.pipe(select(selectAllPreferredSitesTemp))),
      mergeMap(([action, preferredSites]) => {
        const { payload } = action as RemoveSelectedPreferredSiteTempSignal;

        const preferredSite = preferredSites
          ? preferredSites.find((x) => x.site === payload.id)
          : null;

        return this.preferredSitePersistenceService.delete(preferredSite.id).pipe(
          map(
            () =>
              new DeleteOneMessage({
                id: preferredSite.id,
              })
          ),
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while selected the site: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Site can’t be selected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Site can’t be selected. Please try again in a few minutes',
            })
          )
        );
      })
    )
  );

  selectAllPreferredSitesTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectAllPreferredSitesTempSignal>(SelectAllPreferredSitesTempSignal.TYPE),
      concatLatestFrom(() => [this.siteService.getAll(), this.preferredSiteService.getAllTemp()]),
      mergeMap(([_, sites, preferredSites]) => {
        const siteIds = sites.map((site) => site.id);
        const preferredSiteIds = preferredSites.map((site) => site.site);

        const unSelectedSiteIds = siteIds.filter((site) => !preferredSiteIds.includes(site));
        const unSelectedSites = unSelectedSiteIds.map((site) => ({ site }));

        const actions$ = this.preferredSiteService.bulkCreate(unSelectedSites).pipe(
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while selected the sites: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Sites can’t be selected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Sites can’t be selected. Please try again in a few minutes',
            })
          )
        );
        return concat(
          of(new SelectAllInProgressMessage({})),
          actions$,
          of(new SelectAllCompleteMessage({}))
        );
      })
    )
  );

  clearAllPreferredSitesTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ClearAllPreferredSitesTempSignal>(ClearAllPreferredSitesTempSignal.TYPE),
      concatLatestFrom(() => this.preferredSiteService.getAllTemp()),
      mergeMap(([_, sites]) =>
        this.preferredSiteService.bulkDelete({ id: sites.map(({ id }) => id) }).pipe(
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while unselected the sites: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Sites can’t be unselected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Sites can’t be unselected. Please try again in a few minutes',
            })
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private preferredSitePersistenceService: PreferredSitePersistenceService,
    private preferredSiteService: PreferredSiteService,
    private siteService: SiteService
  ) {}
}
