import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NavItem } from '../../interfaces/nav-item';
import { MenuListItemComponent } from '../../molecules/menu-list-item/menu-list-item.component';

@Component({
  selector: 'locumsnest-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss'],
  standalone: true,
  imports: [MenuListItemComponent, RouterModule, NgClass],
  animations: [
    trigger('slideInOut', [
      state('true', style({ overflow: 'hidden', width: '175px', height: '100%' })),
      state('false', style({ width: '60px', height: '100%' })),
      transition('false <=> true', animate('100ms ease-in-out')),
    ]),
  ],
})
export class SideNavBarComponent {
  @Input() idx: string;
  @Input() itemList: NavItem[];
  @Input() filtersPanelExpanded: boolean;
  @Input() nestPanelExpanded: boolean;
  @Input() filterCount: number;
  @Input() currentUrl: string;
  @Input() hospitalOfficerPath: string;
  @Input() isAgencyPlatform: boolean;
  @Input() showMessagingPanel: boolean;
  @Input() hasUnreadMessages: boolean;
  @Output() toggle: EventEmitter<void> = new EventEmitter<void>();
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() expanded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filterMenuClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() messagingMenuClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() menuItemClicked = new EventEmitter<string>();

  menuExpanded = false;

  onToggle(event) {
    this.toggle.emit(event);
  }

  onMenuExpanded() {
    this.menuExpanded = !this.menuExpanded;
    this.expanded.emit(this.menuExpanded);
  }

  onFilterMenuClicked() {
    this.filterMenuClicked.emit();
  }

  onMenuItemClicked(path: string) {
    this.menuItemClicked.emit(path);
  }

  onMessagingMenuClicked() {
    this.messagingMenuClicked.emit();
  }

  onClick(event) {
    this.clicked.emit(event);
  }
}
