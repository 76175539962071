import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { difference } from 'lodash-es';
import { concat, merge, Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';

import { PreferredPassportProfessionPersistenceService } from '../preferred-passport-profession.persistence.service';
import { PreferredPassportProfessionService } from '../preferred-passport-profession.service';
import {
  SelectAllCompleteMessage,
  SelectAllInProgressMessage,
} from './../../../core/+state/filter-ui/filter-ui.messages';
import {
  IPreferredProfessionEntity,
  IPreferredProfessionPostEntity,
} from './../../../interfaces/api/preferred-profession-entity';
import { ProfessionService } from './../../../profession/+state/profession.service';
import { conditionalErrorHandler } from './../preferred-passport-profession.adapter';
import { AddOneMessage, DeleteOneMessage } from './preferred-passport-profession.messages';
import {
  AddSelectedPreferredPassportProfessionTempSignal,
  ClearAllPreferredPassportProfessionsTempSignal,
  RemoveSelectedPreferredPassportProfessionTempSignal,
  SelectAllPreferredPassportProfessionsTempSignal,
} from './preferred-passport-profession.signals';

@Injectable()
export class PreferredPassportProfessionTempEffects {
  addSelectedPreferredPassportProfessionTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddSelectedPreferredPassportProfessionTempSignal>(
        AddSelectedPreferredPassportProfessionTempSignal.TYPE
      ),
      mergeMap((action) => {
        const { payload } = action as AddSelectedPreferredPassportProfessionTempSignal;

        const data: IPreferredProfessionPostEntity = {
          profession: payload.id,
        };

        return this.persistenceService
          .create<IPreferredProfessionPostEntity, IPreferredProfessionEntity>(data)
          .pipe(
            map(
              (response: IPreferredProfessionEntity) =>
                new AddOneMessage({
                  entity: response,
                })
            ),
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: (message) =>
                  `An error occurred while selected the profession: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Profession can’t be selected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Profession can’t be selected. Please try again in a few minutes',
              })
            )
          );
      })
    )
  );

  removeSelectedPreferredPassportProfessionTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveSelectedPreferredPassportProfessionTempSignal>(
        RemoveSelectedPreferredPassportProfessionTempSignal.TYPE
      ),
      mergeMap((action) =>
        this.preferredPassportProfessionService
          .getPreferredPassportProfessionByProfessionId(action.payload.id)
          .pipe(first())
      ),
      mergeMap((preferredPassportProfession) =>
        this.persistenceService.delete(preferredPassportProfession.id).pipe(
          mergeMap(
            () =>
              merge(
                of(
                  new DeleteOneMessage({
                    id: preferredPassportProfession.id,
                  })
                )
              ) as Observable<Action>
          ),
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while selected the profession: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Profession can’t be selected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Profession can’t be selected. Please try again in a few minutes',
            })
          )
        )
      )
    )
  );

  selectAllPreferredPassportProfessionsTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectAllPreferredPassportProfessionsTempSignal>(
        SelectAllPreferredPassportProfessionsTempSignal.TYPE
      ),
      concatLatestFrom(() => [
        this.professionService.getAllActive(),
        this.preferredPassportProfessionService.getAllTemp(),
      ]),
      mergeMap(([_, professions, preferredProfessions]) => {
        const professionIds = professions.map((profession) => profession.id);
        const preferredProfessionIds = preferredProfessions.map(
          (profession) => profession.profession
        );

        const unSelectedProfessionIds = difference(professionIds, preferredProfessionIds);
        const unSelectedProfessions = unSelectedProfessionIds.map((profession) => ({
          profession,
        }));

        return concat(
          of(new SelectAllInProgressMessage({})),
          this.preferredPassportProfessionService.bulkCreate(unSelectedProfessions).pipe(
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: (message) =>
                  `An error occurred while selected the professions: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Professions can’t be selected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Professions can’t be selected. Please try again in a few minutes',
              })
            )
          ),
          of(new SelectAllCompleteMessage({}))
        );
      })
    )
  );

  clearAllPreferredPassportProfessionsTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ClearAllPreferredPassportProfessionsTempSignal>(
        ClearAllPreferredPassportProfessionsTempSignal.TYPE
      ),
      mergeMap((action) =>
        merge(
          this.preferredPassportProfessionService.clear().pipe(
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: (message) =>
                  `An error occurred while clearing professions`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Professions can’t be unselected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Professions can’t be unselected. Please try again in a few minutes',
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private persistenceService: PreferredPassportProfessionPersistenceService,
    private professionService: ProfessionService,
    private preferredPassportProfessionService: PreferredPassportProfessionService
  ) {}
}
