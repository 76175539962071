<div
  class="menu-item"
  [id]="idx + '__' + item.code"
  [ngClass]="{
    'menu-item-expanded': menuExpanded,
    'first-element': item.code === 'nest' && isFirstElement,
    'first-agency-element': isFirstElement && isAgencyPlatform
  }"
  (click)="onItemSelected(item)"
>
  <span
    [ngStyle]="{ visibility: menuExpanded ? 'hidden' : 'visible' }"
    [ngClass]="{ 'item-display-name': true, 'item-display-name--expanded': menuExpanded }"
    >{{ item.displayName }}</span
  >
  <div *ngIf="isLastElement && itemListLength > 2">
    <div class="line"></div>
  </div>
  <div
    class="item"
    [ngClass]="{ 'nest-item': item.code === 'nest', 'menu-expanded': menuExpanded }"
  >
    <div
      class="icon {{ item.iconName }}"
      [class.active]="isActive(item, currentUrl)"
      [ngClass]="{ 'menu-expanded': menuExpanded && item.code === 'nest' }"
    ></div>
    <div
      class="display-name"
      [class.active]="isActive(item, currentUrl)"
      [ngClass]="{
        'nest-item': item.code === 'nest',
        'item-display-name': true,
        'item-display-name--expanded': menuExpanded
      }"
      [innerHTML]="item.displayName"
    ></div>
  </div>
  <div *ngIf="item?.hasFilterCount && filterCount > 0" class="filter-count"></div>
  <div *ngIf="item.code === 'filters' && itemListLength > 2">
    <div class="line"></div>
  </div>
</div>
