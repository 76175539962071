import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { difference } from 'lodash-es';
import { concat, merge, of } from 'rxjs';
import { catchError, exhaustMap, first, map, mergeMap } from 'rxjs/operators';

import { selectFilterUiState } from '../../../core/+state/filter-ui';
import {
  SelectAllCompleteMessage,
  SelectAllInProgressMessage,
} from '../../../core/+state/filter-ui/filter-ui.messages';
import {
  IPreferredSpecialtyCategoryEntity,
  IPreferredSpecialtyCategoryPostEntity,
} from '../../../interfaces/api/preferred-specialty-category-entity';
import { PreferredProfessionSpecialtyService } from '../../../preferred-profession-specialty/+state/preferred-profession-specialty.service';
import { PreferredProfessionService } from '../../../preferred-profession/+state/preferred-profession.service';
import { PreferredSpecialtyCategoryPersistenceService } from '../preferred-specialty-category.persistence.service';
import { PreferredSpecialtyCategoryService } from '../preferred-specialty-category.service';
import { ProfessionService } from './../../../profession/+state/profession.service';
import { conditionalErrorHandler } from './../preferred-specialty-category.adapter';
import { AddOneMessage, DeleteOneMessage } from './preferred-specialty-category-temp.messages';
import {
  AddSelectedPreferredSpecialtyCategoryTempSignal,
  ClearAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal,
  ClearAllPreferredSpecialtyCategoriesTempSignal,
  RemoveSelectedPreferredSpecialtyCategoryTempSignal,
  SelectAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal,
  SelectAllPreferredSpecialtyCategoriesTempSignal,
} from './preferred-specialty-category-temp.signals';

@Injectable()
export class PreferredSpecialtyCategoryTempEffects {
  addSelectedPreferredSpecialtyCategoryTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddSelectedPreferredSpecialtyCategoryTempSignal>(
        AddSelectedPreferredSpecialtyCategoryTempSignal.TYPE
      ),
      concatLatestFrom(() => this.preferredProfessionSpecialtyService.getAllTemp()),
      mergeMap(([action, preferredProfessionSpecialties]) => {
        const { payload } = action as AddSelectedPreferredSpecialtyCategoryTempSignal;

        const data: IPreferredSpecialtyCategoryPostEntity = {
          specialtyCategory: payload.id,
          profession: payload.professionId,
        };

        return merge(
          this.professionService
            .getActiveProfessionSpecialtiesByCategory(payload.professionId, payload.id)
            .pipe(
              first(),
              mergeMap((professionSpecialties) => {
                const professionSpecialtyIds = professionSpecialties.map(({ id }) => id);
                const preferredProfessionSpecialtyIds = preferredProfessionSpecialties.map(
                  ({ professionSpecialty }) => professionSpecialty
                );
                const unselectedIds = difference(
                  professionSpecialtyIds,
                  preferredProfessionSpecialtyIds
                );
                return this.preferredProfessionSpecialtyService
                  .bulkCreate(unselectedIds.map((professionSpecialty) => ({ professionSpecialty })))
                  .pipe(
                    catchError(
                      conditionalErrorHandler({
                        errorEventMessageHandler: () =>
                          `An error occurred while selecting sub-specialties`,
                        errorDetailMessageHandler: (message) =>
                          `Sorry! Sub-specialties can’t be selected. The error was: ${message}`,
                        unknownErrorMessage:
                          // eslint-disable-next-line max-len
                          'Sorry! Sub-specialties can’t be selected. Please try again in a few minutes',
                      })
                    )
                  );
              })
            ),
          this.persistenceService
            .create<IPreferredSpecialtyCategoryPostEntity, IPreferredSpecialtyCategoryEntity>(data)
            .pipe(
              map(
                (response: IPreferredSpecialtyCategoryEntity) =>
                  new AddOneMessage({
                    entity: response,
                  })
              ),
              catchError(
                conditionalErrorHandler({
                  errorEventMessageHandler: (message) =>
                    `An error occurred while selected the  specialty: ${message}`,
                  errorDetailMessageHandler: (message) =>
                    `Sorry! Specialty can’t be selected. The error was: ${message}`,
                  unknownErrorMessage:
                    'Sorry! Specialty can’t be selected. Please try again in a few minutes',
                })
              )
            )
        );
      })
    )
  );

  removeSelectedPreferredSpecialtyCategoryTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveSelectedPreferredSpecialtyCategoryTempSignal.TYPE),
      concatLatestFrom(() => this.preferredSpecialtyCategoryService.getAllTemp()),
      mergeMap(([action, preferredSpecialties]) => {
        const { payload } = action as RemoveSelectedPreferredSpecialtyCategoryTempSignal;

        const preferredSpecialty = preferredSpecialties
          ? preferredSpecialties.find(
              ({ specialtyCategory, profession }) =>
                specialtyCategory === payload.id && profession === payload.professionId
            )
          : null;

        return this.persistenceService.delete(preferredSpecialty.id).pipe(
          mergeMap(() =>
            merge(
              of(
                new DeleteOneMessage({
                  id: preferredSpecialty.id,
                })
              ),
              this.preferredProfessionSpecialtyService.deleteSpecialtyCategorySubSpecialties(
                payload.id,
                preferredSpecialty.profession
              )
            )
          ),
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while unselected the  specialty: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Specialty can’t be unselected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Specialty can’t be unselected. Please try again in a few minutes',
            })
          )
        );
      })
    )
  );

  selectAllForProfession$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal>(
        SelectAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal.TYPE
      ),
      concatLatestFrom(() => this.store.pipe(select(selectFilterUiState))),
      exhaustMap(([_, filterUiState]) => {
        const professionId = filterUiState.selectedCategoryId;

        return merge(
          this.preferredProfessionSpecialtyService.getInexistentByProfession(professionId).pipe(
            first(),
            mergeMap((unselectedIds) =>
              this.preferredProfessionSpecialtyService
                .bulkCreate(unselectedIds.map((professionSpecialty) => ({ professionSpecialty })))
                .pipe(
                  catchError(
                    conditionalErrorHandler({
                      errorEventMessageHandler: (message) =>
                        `An error occurred while selected the sub-specialties: ${message}`,
                      errorDetailMessageHandler: (message) =>
                        `Sorry! Sub-Specialties can’t be selected. The error was: ${message}`,
                      unknownErrorMessage:
                        // eslint-disable-next-line max-len
                        'Sorry! Sub-Specialties can’t be selected. Please try again in a few minutes',
                    })
                  )
                )
            )
          ),
          this.preferredSpecialtyCategoryService.getInexistentByProfession(professionId).pipe(
            first(),
            mergeMap((unSelectedSpecialtyCategoryIds) => {
              const unSelectedSpecialtyCategories = unSelectedSpecialtyCategoryIds.map(
                (specialtyCategory) => ({
                  specialtyCategory,
                  profession: professionId,
                })
              );

              return this.preferredSpecialtyCategoryService
                .bulkCreate(unSelectedSpecialtyCategories)
                .pipe(
                  catchError(
                    conditionalErrorHandler({
                      errorEventMessageHandler: (message) =>
                        `An error occurred while selected the specialties: ${message}`,
                      errorDetailMessageHandler: (message) =>
                        `Sorry! Specialties can’t be selected. The error was: ${message}`,
                      unknownErrorMessage:
                        'Sorry! Specialties can’t be selected. Please try again in a few minutes',
                    })
                  )
                );
            })
          )
        );
      })
    )
  );

  selectAllPreferredSpecialtyCategoriesTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectAllPreferredSpecialtyCategoriesTempSignal>(
        SelectAllPreferredSpecialtyCategoriesTempSignal.TYPE
      ),
      concatLatestFrom(() => this.preferredProfessionService.getAllTempProfessionIds()),
      exhaustMap(([_, preferredProfessionIds]) => {
        const actions$ = merge(
          this.preferredProfessionSpecialtyService
            .getInexistentByProfessionList(preferredProfessionIds)
            .pipe(
              first(),
              mergeMap((unselectedIds) =>
                this.preferredProfessionSpecialtyService
                  .bulkCreate(unselectedIds.map((professionSpecialty) => ({ professionSpecialty })))
                  .pipe(
                    catchError(
                      conditionalErrorHandler({
                        errorEventMessageHandler: (message) =>
                          `An error occurred while selected the sub-specialties: ${message}`,
                        errorDetailMessageHandler: (message) =>
                          `Sorry! Sub-Specialties can’t be selected. The error was: ${message}`,
                        unknownErrorMessage:
                          // eslint-disable-next-line max-len
                          'Sorry! Sub-Specialties can’t be selected. Please try again in a few minutes',
                      })
                    )
                  )
              )
            ),
          this.preferredSpecialtyCategoryService
            .getInexistentByProfessionList(preferredProfessionIds)
            .pipe(
              first(),
              mergeMap((unSelectedSpecialtyCategories) =>
                this.preferredSpecialtyCategoryService
                  .bulkCreate(unSelectedSpecialtyCategories)
                  .pipe(
                    catchError(
                      conditionalErrorHandler({
                        errorEventMessageHandler: (message) =>
                          `An error occurred while selected the specialties: ${message}`,
                        errorDetailMessageHandler: (message) =>
                          `Sorry! Specialties can’t be selected. The error was: ${message}`,
                        unknownErrorMessage:
                          'Sorry! Specialties can’t be selected. Please try again in a few minutes',
                      })
                    )
                  )
              )
            )
        );
        return concat(
          of(new SelectAllInProgressMessage({})),
          actions$,
          of(new SelectAllCompleteMessage({}))
        );
      })
    )
  );

  clearAllPreferredSpecialtyCategoriesTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ClearAllPreferredSpecialtyCategoriesTempSignal>(
        ClearAllPreferredSpecialtyCategoriesTempSignal.TYPE
      ),
      exhaustMap(() => {
        const actions$ = merge(
          this.preferredSpecialtyCategoryService.clear().pipe(
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: () => `An error occurred while clearing professions`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Professions can’t be unselected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Professions can’t be unselected. Please try again in a few minutes',
              })
            )
          ),
          this.preferredProfessionSpecialtyService.clear().pipe(
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: () => `An error occurred while clearing specialties`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Sub-specialties can’t be unselected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Sub-specialties can’t be unselected. Please try again in a few minutes',
              })
            )
          )
        );
        return concat(
          of(new SelectAllInProgressMessage({})),
          actions$,
          of(new SelectAllCompleteMessage({}))
        );
      })
    )
  );

  clearAllForProfession$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ClearAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal>(
        ClearAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal.TYPE
      ),
      concatLatestFrom(() => this.store.pipe(select(selectFilterUiState))),
      exhaustMap(([_, filterUiState]) => {
        const profession = filterUiState.selectedCategoryId;
        return merge(
          this.preferredSpecialtyCategoryService.getAllIdsByProfession(profession).pipe(
            first(),
            mergeMap((specialtyCategories) =>
              this.preferredSpecialtyCategoryService.bulkDelete(specialtyCategories).pipe(
                catchError(
                  conditionalErrorHandler({
                    errorEventMessageHandler: (message) =>
                      `An error occurred while unselected the specialties: ${message}`,
                    errorDetailMessageHandler: (message) =>
                      `Sorry! Specialties can’t be unselected. The error was: ${message}`,
                    unknownErrorMessage:
                      'Sorry! Specialties can’t be unselected. Please try again in a few minutes',
                  })
                )
              )
            )
          ),
          this.preferredProfessionSpecialtyService.getAllTempIdsByProfession(profession).pipe(
            first(),
            mergeMap((id) =>
              this.preferredProfessionSpecialtyService.bulkDelete({ id }).pipe(
                catchError(
                  conditionalErrorHandler({
                    errorEventMessageHandler: () =>
                      `An error occurred while clearing sub-specialties`,
                    errorDetailMessageHandler: (message) =>
                      `Sorry! Sub-specialties can’t be unselected. The error was: ${message}`,
                    unknownErrorMessage:
                      'Sorry! Sub-specialties can’t be unselected. ' +
                      'Please try again in a few minutes',
                  })
                )
              )
            )
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private persistenceService: PreferredSpecialtyCategoryPersistenceService,
    private preferredSpecialtyCategoryService: PreferredSpecialtyCategoryService,
    private preferredProfessionSpecialtyService: PreferredProfessionSpecialtyService,
    private professionService: ProfessionService,
    private preferredProfessionService: PreferredProfessionService
  ) {}
}
