import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PermissionService } from '../../permission/+state/permission.service';
import { PermissionBasedAuthGuardService } from '../../permission/services/permission-based-auth-guard.service';

@Injectable({
  providedIn: 'root',
})
export class AgencyAuthGuardService extends PermissionBasedAuthGuardService {
  protected readonly activated = true;
  protected readonly matchPolicy = 'all';
  protected readonly failureUrl: string = 'dashboard';
  protected readonly requiredPermissions = [
    'view_externalstaffingcandidatebid',
    'add_externalstaffingcandidatebid',
    'change_externalstaffingcandidatebid',
    'view_staffingcascade',
    'view_joblisting',
  ];

  constructor(protected permissionService: PermissionService, protected router: Router) {
    super();
  }
}
