<div class="side-nav-bar">
  <div class="side-nav-items" [@slideInOut]="menuExpanded">
    @for (item of itemList; track item.code; let first = $first; let last = $last) {
      <div
        class="menu-item"
        [ngClass]="{
          'first-element': first && !isAgencyPlatform,
          'first-agency-element': first && isAgencyPlatform,
          item: item.code !== 'nest'
        }"
      >
        <locumsnest-menu-list-item
          class="menu-list-item"
          [idx]="idx + '__nav-bar-item'"
          [item]="item"
          [depth]="0"
          [filterCount]="filterCount"
          [isFirstElement]="first"
          [isLastElement]="last"
          [currentUrl]="currentUrl"
          [filtersPanelExpanded]="filtersPanelExpanded"
          [nestPanelExpanded]="nestPanelExpanded"
          [menuExpanded]="menuExpanded"
          [hospitalOfficerPath]="hospitalOfficerPath"
          [itemListLength]="itemList.length"
          [isAgencyPlatform]="isAgencyPlatform"
          (clicked)="onClick($event)"
          (toggle)="onToggle($event)"
          (filterMenuClicked)="onFilterMenuClicked()"
        >
        </locumsnest-menu-list-item>
      </div>
    }
    <div class="side-nav-bar__arrows" [ngClass]="{ 'arrows--agency-platform': isAgencyPlatform }">
      @if (menuExpanded) {
        <div
          class="side-nav-bar__arrows__arrow side-nav-bar__arrows__arrow--left"
          [id]="idx + '__nav-bar__arrow-left'"
          (click)="onMenuExpanded()"
        ></div>
      } @else {
        <div
          class="side-nav-bar__arrows__arrow side-nav-bar__arrows__arrow--right"
          [id]="idx + '__nav-bar__arrow-right'"
          (click)="onMenuExpanded()"
        ></div>
      }
    </div>
  </div>
  <div class="right-panel">
    <ng-content></ng-content>
  </div>
</div>

@if (!isAgencyPlatform) {
  <div
    class="messaging-menu"
    [ngClass]="{ 'messaging-menu--open': showMessagingPanel }"
    (click)="onMessagingMenuClicked()"
  >
    <div
      class="messaging-icon"
      [ngClass]="{
        'messaging-icon--open': showMessagingPanel
      }"
    ></div>
    @if (hasUnreadMessages) {
      <div
        class="unread-messages"
        [ngClass]="{
          'unread-messages--open': showMessagingPanel
        }"
      ></div>
    }
  </div>
}
