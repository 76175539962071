import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PermissionService } from '../../../apps/hospital-admin/src/app/permission/+state/permission.service';
import { PermissionBasedAuthGuardService } from '../../../apps/hospital-admin/src/app/permission/services/permission-based-auth-guard.service';

@Injectable({
  providedIn: 'root',
})
export class ControlAuthGuardService extends PermissionBasedAuthGuardService {
  protected readonly activated = true;
  protected readonly matchPolicy = 'any';
  protected readonly failureUrl: string = 'dashboard';
  protected readonly requiredPermissions = [
    'view_configuration',
    'view_configurationcategory',
    'view_trustconfigurationcategory',
    'view_trustconfigurationcategorydetail',
    'view_controlfile',
    'view_configurationcategoryfile',
    'view_trustconfigurationcategorydetailfile',
    'view_trustconfigurationcategorydetailreview',
  ];

  constructor(
    protected permissionService: PermissionService,
    protected router: Router,
  ) {
    super();
  }
}
