import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { IHomePageService } from './interfaces';
import { HOME_PAGE } from './opaque-tokens';

export abstract class BaseHomePageService implements IHomePageService {
  protected abstract router: Router;
  protected abstract route: ActivatedRoute;

  redirectToHome() {
    return this.getHomeUrl().pipe(
      mergeMap((url) => {
        this.router.navigateByUrl(this.route.snapshot.queryParams.redirectUrl || url);
        return of<Action>();
      })
    );
  }
  abstract getHomeUrl(): Observable<string>;
}

@Injectable()
export class SimpleHomePageService extends BaseHomePageService implements IHomePageService {
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    @Inject(HOME_PAGE) private homePage: string
  ) {
    super();
  }

  getHomeUrl(): Observable<string> {
    return of(this.homePage);
  }
}
