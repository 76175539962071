import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ComponentsModule } from '@locumsnest/components';
import { FirebaseModule } from '@locumsnest/firebase/src';

import { HospitalOfficerModule } from '../hospital-officer/hospital-officer.module';
import { JobListingTypeModule } from '../job-listing-type/job-listing-type.module';
import { PreferredPassportProfessionTempEffects } from '../preferred-passport-profession/+state/temp/preferred-passport-profession-temp.effects';
import { PreferredProfessionSpecialtyTempEffects } from '../preferred-profession-specialty/+state/temp/preferred-profession-specialty-temp.effects';
import { PreferredProfessionTempEffects } from '../preferred-profession/+state/temp/preferred-profession-temp.effects';
import { PreferredRosterEffects } from '../preferred-roster/+state';
import { PreferredRosterTempEffects } from '../preferred-roster/+state/temp/preferred-roster-temp.effects';
import { PreferredSiteTempEffects } from '../preferred-site/+state/temp/preferred-site-temp.effects';
import { PreferredPassportProfessionEffects } from './../preferred-passport-profession/+state/preferred-passport-profession.effects';
import { PreferredProfessionSpecialtyEffects } from './../preferred-profession-specialty/+state/preferred-profession-specialty.effects';
import { PreferredProfessionEffects } from './../preferred-profession/+state/preferred-profession.effects';
import { PreferredSiteEffects } from './../preferred-site/+state/preferred-site.effects';
import { PreferredSpecialtyCategoryEffects } from './../preferred-specialty-category/+state/preferred-specialty-category.effects';
import { PreferredSpecialtyCategoryTempEffects } from './../preferred-specialty-category/+state/temp/preferred-specialty-category-temp.effects';
import { PreferredWardEffects } from './../preferred-ward/+state/preferred-ward.effects';
import { PreferredWardTempEffects } from './../preferred-ward/+state/temp/preferred-ward-temp.effects';
import { LayoutUiEffects } from './+state/ui';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    InfiniteScrollModule,
    HospitalOfficerModule,
    JobListingTypeModule,
    FormsModule,
    FirebaseModule,
    EffectsModule.forFeature([
      LayoutUiEffects,
      PreferredSiteEffects,
      PreferredWardEffects,
      PreferredRosterEffects,
      PreferredSpecialtyCategoryEffects,
      PreferredProfessionSpecialtyEffects,
      PreferredProfessionEffects,
      PreferredProfessionSpecialtyTempEffects,
      PreferredSpecialtyCategoryTempEffects,
      PreferredSiteTempEffects,
      PreferredWardTempEffects,
      PreferredRosterTempEffects,
      PreferredProfessionTempEffects,
      PreferredPassportProfessionEffects,
      PreferredPassportProfessionTempEffects,
    ]),
  ],
})
export class CoreModule {}
