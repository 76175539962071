import { ActionReducerMap } from '@ngrx/store';
import * as fromEntity from './external-staffing-provider-officer.reducer';
import { IExternalStaffingProviderOfficerSharedState } from './interfaces';
export * from './external-staffing-provider-officer.effects';
export * from './external-staffing-provider-officer.messages';
export * from './interfaces';
export const reducers: ActionReducerMap<IExternalStaffingProviderOfficerSharedState> = {
  entityState: fromEntity.entityReducer,
  pagination: fromEntity.paginationReducer,
};
