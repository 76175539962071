import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { UpdateEntityStateMessage } from './preferred-profession.messages';
import { selectPreferredProfessionTempState } from './preferred-profession.selectors';
import { UpdateEntityStatePreferredProfessionSignal } from './preferred-profession.signals';

@Injectable()
export class PreferredProfessionEffects {
  updateEntityStatePreferredProfessionSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateEntityStatePreferredProfessionSignal>(
        UpdateEntityStatePreferredProfessionSignal.TYPE
      ),
      concatLatestFrom(() => this.store.pipe(select(selectPreferredProfessionTempState))),
      map(
        ([_, preferredProfessionTempState]) =>
          new UpdateEntityStateMessage({
            preferredProfessionTempState,
          })
      )
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}
