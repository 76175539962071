import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { UpdateEntityStateMessage } from './preferred-passport-profession.messages';
import { selectPreferredPassportProfessionTempState } from './preferred-passport-profession.selectors';
import { UpdateEntityStatePreferredPassportProfessionSignal } from './preferred-passport-profession.signals';

@Injectable()
export class PreferredPassportProfessionEffects {
  updateEntityStatePreferredPassportProfessionSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateEntityStatePreferredPassportProfessionSignal>(
        UpdateEntityStatePreferredPassportProfessionSignal.TYPE
      ),
      concatLatestFrom(() => this.store.pipe(select(selectPreferredPassportProfessionTempState))),
      map(
        ([_, preferredPassportProfessionTempState]) =>
          new UpdateEntityStateMessage({
            preferredPassportProfessionTempState,
          })
      )
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}
