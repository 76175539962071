import {
  agencyNavItems,
  communityNavItems,
  controlNavItems,
  intelligenceNavItems,
  linkNavItems,
  locumsnestNavItems,
  passportNavItems,
  rotaNavItems,
} from '@locumsnest/components/src/lib/interfaces/nav-item';

import { PRODUCT_CODES } from './constants';

export const MICRO_APP_CONFIGURATION = [
  {
    code: PRODUCT_CODES.MATCH,
    display: 'Match',
    basePath: 'dashboard',
    pathPrefix: '',
    navItems: locumsnestNavItems,
    accessRequestUrl: 'https://locumsnest.co.uk/match-8yn2yi2capa3',
  },
  {
    code: PRODUCT_CODES.INTELLIGENCE,
    display: 'Intelligence',
    basePath: 'intelligence/dashboard',
    pathPrefix: 'intelligence',
    navItems: intelligenceNavItems,
    accessRequestUrl: 'https://locumsnest.co.uk/intelligence-kn9r0a36r9cm',
  },
  {
    code: PRODUCT_CODES.COMMUNITY,
    display: 'Community',
    basePath: 'community/dashboard',
    pathPrefix: 'community',
    navItems: communityNavItems,
    accessRequestUrl: 'https://locumsnest.co.uk/community-i757xe183d25',
  },
  {
    code: PRODUCT_CODES.PASSPORT_PLUS,
    display: 'Passport Plus',
    basePath: 'passport/dashboard',
    pathPrefix: 'passport',
    navItems: passportNavItems,
    accessRequestUrl: 'https://locumsnest.co.uk/passport-plus-sr5xotod9sut',
  },
  {
    code: PRODUCT_CODES.LINK,
    display: 'Link',
    basePath: 'link/dashboard',
    pathPrefix: 'link',
    navItems: linkNavItems,
    accessRequestUrl: 'https://locumsnest.co.uk/link-7qvisijixq48',
  },
  {
    code: PRODUCT_CODES.ROTA,
    display: 'Rota',
    basePath: 'rota',
    pathPrefix: 'rota',
    navItems: rotaNavItems,
    accessRequestUrl: 'https://locumsnest.co.uk/rota-bz16gwrlle7t',
  },
  {
    code: PRODUCT_CODES.CONTROL,
    display: 'Control',
    basePath: 'control',
    pathPrefix: 'control',
    navItems: controlNavItems,
    accessRequestUrl: 'https://locumsnest.co.uk/rota-bz16gwrlle7t',
  },
  {
    code: PRODUCT_CODES.AGENCY,
    display: 'Agency',
    basePath: 'agency',
    pathPrefix: 'agency',
    navItems: agencyNavItems,
    accessRequestUrl: '',
  },
];
