import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { concat, first, map, switchMap } from 'rxjs';

import { BaseHomePageService } from '@locumsnest/auth/src/lib/session-auth/services/home-page.service';
import { HOME_PAGE } from '@locumsnest/auth/src/lib/session-auth/services/opaque-tokens';

import { AuthGroupService } from '../../../auth-group/+state/auth-group.service';
import { IHomePageService } from './../../../../../../../libs/auth/src/lib/session-auth/services/interfaces';

@Injectable({
  providedIn: 'root',
})
export class HomePageService extends BaseHomePageService implements IHomePageService {
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private authGroupService: AuthGroupService,
    private store: Store,
    @Inject(HOME_PAGE) private homePage: string,
  ) {
    super();
  }

  redirectToHome() {
    return concat(this.authGroupService.fetchMy(), super.redirectToHome());
  }

  getHomeUrl() {
    return this.authGroupService.fetchMy().pipe(
      switchMap((action) => {
        this.store.dispatch(action);
        return this.getMainMicroApp().pipe(
          map((microApp) => {
            if (microApp) return microApp.basePath;
            return this.homePage;
          }),
        );
      }),
    );
  }

  getMainMicroApp() {
    return this.authGroupService.getCurrentGroupMicroAppConfiguration().pipe(first());
  }
}
