import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AgencyAuthGuardService } from './auth-guard.service';

@Injectable({
  providedIn: 'root',
})
export class InverseAgencyAuthGuardService extends AgencyAuthGuardService {
  protected readonly failureUrl: string = 'agency/dashboard';

  permissionsSatisfied(): Observable<boolean> {
    return super.permissionsSatisfied().pipe(map((res) => !res));
  }
}
