import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { UpdateEntityStateMessage } from './preferred-ward.messages';
import { selectPreferredWardTempState } from './preferred-ward.selectors';
import { UpdateEntityStatePreferredWardSignal } from './preferred-ward.signals';

@Injectable()
export class PreferredWardEffects {
  updateEntityStatePreferredWardSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateEntityStatePreferredWardSignal>(UpdateEntityStatePreferredWardSignal.TYPE),
      concatLatestFrom(() => this.store.pipe(select(selectPreferredWardTempState))),
      map(
        ([_, preferredWardTempState]) =>
          new UpdateEntityStateMessage({
            preferredWardTempState,
          })
      )
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}
