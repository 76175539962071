import {
  animate,
  query,
  sequence,
  stagger,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { isSupported, Messaging, onMessage } from '@angular/fire/messaging';
import { Router, RouterModule } from '@angular/router';
import { TippyDirective } from '@ngneat/helipopper';
import { select, Store } from '@ngrx/store';
import {
  combineLatest,
  EMPTY,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { ToastComponent } from '@locumsnest/components/src/lib/atoms/toast/toast.component';
import { LocumsNestComponent } from '@locumsnest/components/src/lib/core/locums-nest-component';
import { NavItem, onboardingNavItems } from '@locumsnest/components/src/lib/interfaces/nav-item';
import { SideNavBarComponent } from '@locumsnest/components/src/lib/organisms/side-nav-bar/side-nav-bar.component';
import { IAlertState } from '@locumsnest/core/src';
import { IMicroAppListItemState } from '@locumsnest/core/src/lib/micro-app/interfaces';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';
import { selectUrl } from '@locumsnest/core/src/lib/router/router-selectors';
import { CheckForNotificationsPermissionSignal } from '@locumsnest/firebase/src';
import { GetCommentsForRequestSignal } from '@locumsnest/zendesk/comments/src/lib/+state/zendesk-comments.signals';
import {
  FetchRequestByIdSignal,
  ZendeskRequestsModule,
  ZendeskRequestsService,
} from '@locumsnest/zendesk/requests/src';

import { AuthGroupService } from '../../../auth-group/+state/auth-group.service';
import { AUTH_GROUPS } from '../../../auth-group/constants';
import { ExternalStaffingProviderOfficerService } from '../../../external-staffing-provider-officer/+state/external-staffing-provider-officer.service';
import { ExternalStaffingProviderService } from '../../../external-staffing-provider/+state/external-staffing-provider.service';
import { HealthCheckService } from '../../../health-check/+state/health-check.service';
import { HealthCheckModule } from '../../../health-check/health-check.module';
import { ChameleonService } from '../../../hospital-officer/+state/chameleon-service';
import { HospitalOfficerService } from '../../../hospital-officer/+state/hospital-officer.service';
import { HospitalService } from '../../../hospital/+state/hospital.services';
import { PassportFilterService } from '../../../passport/+state/passport-filter.service';
import { FilterService } from '../../+state/filter.service';
import {
  InitializeLayoutContainerSignal,
  ShowHideHeaderFilterInformationSignal,
  ShowMessagingPanelSignal,
  ShowProductPanelSignal,
  ToggleFiltersPanelSignal,
  ToggleProductPanelSignal,
} from '../../+state/ui/ui.signals';
import { getServerName, PRODUCT_CODES } from '../../constants';
import { CoreModule } from '../../core.module';
import { IOfficerPersonalDetails } from '../../interfaces';
import { LayoutService, RouterService } from '../../services';
import { IHeaderFilterInfo } from '../../services/interfaces';
import { SidebarCloseAnimation, SidebarOpenAnimation } from './animations';

const animationParams = {
  menuWidth: '250px',
  animationStyle: '500ms ease',
};

interface FireBaseMessage {
  ticket_id: string;
  type: string;
}

@Component({
  selector: 'locumsnest-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    SideNavBarComponent,
    ToastComponent,
    CoreModule,
    HealthCheckModule,
    ZendeskRequestsModule,
    TippyDirective,
  ],
  animations: [
    trigger('dropDownMenu', [
      transition(':enter', [
        style({ height: 0, overflow: 'hidden' }),
        query('.nest-item', [style({ opacity: 0, transform: 'translateY(-50px)' })]),
        sequence([
          animate('100ms', style({ height: '*' })),
          query('.nest-item', [
            stagger(50, [animate('200ms ease', style({ opacity: 1, transform: 'none' }))]),
          ]),
        ]),
      ]),
      transition(':leave', [
        style({ height: '*', overflow: 'hidden' }),
        query('.nest-item', [style({ opacity: 1, transform: 'none' })]),
        sequence([
          query('.nest-item', [
            stagger(-50, [
              animate('200ms ease', style({ opacity: 0, transform: 'translateY(-50px)' })),
            ]),
          ]),
          animate('100ms', style({ height: 0 })),
        ]),
      ]),
    ]),
    trigger('sideMenu', [
      transition(':enter', [
        useAnimation(SidebarOpenAnimation, {
          params: {
            ...animationParams,
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(SidebarCloseAnimation, {
          params: {
            ...animationParams,
          },
        }),
      ]),
    ]),
  ],
})
export class LayoutContainerComponent extends LocumsNestComponent implements OnInit, OnDestroy {
  public readonly idPageName = 'layout-container';
  message$: Observable<FireBaseMessage> = EMPTY;

  public alertState$: Observable<IAlertState>;
  public navItems$: Observable<NavItem[]>;
  public filterCount$: Observable<number>;
  public currentUrl$: Observable<string>;
  public showFiltersPanel$: Observable<boolean>;
  public showMessagingPanel$: Observable<boolean>;
  public hasUnreadMessages$: Observable<boolean>;
  public showProductPanel$: Observable<boolean>;
  public showHeaderFilterInformation$: Observable<boolean>;
  public officerPersonalDetails$: Observable<IOfficerPersonalDetails>;
  public organizationDetailsName$: Observable<string>;
  public isSandBoxed$: Observable<boolean>;
  public headerFilterInfo$: Observable<IHeaderFilterInfo>;
  public hasRosteredJobListingPermission$: Observable<boolean>;
  public microAppBasePath$: Observable<string>;
  public microAppDisplayName$: Observable<string>;
  public microAppList$: Observable<IMicroAppListItemState[]>;
  public isAgencyPlatform$: Observable<boolean>;
  public hospitalOfficerPath$: Observable<string>;

  public canSelectAll$: Observable<boolean>;
  public serverName: string;
  public nextShutDown$: Observable<Date>;

  menuExpanded = false;
  responsiveMenuOpen = false;

  public readonly valueEnter = { value: 'enter' };
  public readonly valueLeave = { value: 'leave' };

  constructor(
    @Optional() private messaging: Messaging,
    private store: Store,
    private hospitalService: HospitalService,
    private hospitalOfficerService: HospitalOfficerService,
    private externalStaffingProviderOfficerService: ExternalStaffingProviderOfficerService,
    private externalStaffingProviderService: ExternalStaffingProviderService,
    private layoutService: LayoutService,
    private filterService: FilterService,
    private passportFilterService: PassportFilterService,
    private microAppService: MicroAppService,
    private routerService: RouterService,
    protected router: Router,
    private chameleonService: ChameleonService,
    private healthCheckService: HealthCheckService,
    private zendeskRequestService: ZendeskRequestsService,
    private authGroupService: AuthGroupService,
  ) {
    super();
    this.alertState$ = layoutService.getAlertState();
    this.filterCount$ = this.microAppService.getMicroAppCode().pipe(
      mergeMap((app) => {
        if (app === PRODUCT_CODES.PASSPORT_PLUS) {
          return this.passportFilterService.getFilterCount();
        } else {
          return this.filterService.getFilterCount();
        }
      }),
    );
    this.showFiltersPanel$ = this.layoutService.getShowFiltersPanel();
    this.showMessagingPanel$ = this.layoutService.getShowMessagingPanel();
    this.showProductPanel$ = this.layoutService.getShowProductPanel();
    this.nextShutDown$ = this.healthCheckService.getNextShutDown();
    this.currentUrl$ = this.store.pipe(select(selectUrl));
    this.officerPersonalDetails$ = this.microAppService
      .isAgencyPlatform()
      .pipe(
        switchMap((isAgencyPlatform) =>
          isAgencyPlatform
            ? this.externalStaffingProviderOfficerService.getAssignedUserDetails()
            : this.hospitalOfficerService.getHospitalOfficerPersonalDetails(),
        ),
      );
    this.organizationDetailsName$ = merge(
      this.externalStaffingProviderService.getAssignedName(),
      this.hospitalService.getAssignedName(),
    );
    this.showHeaderFilterInformation$ = this.layoutService.getShowHeaderFilterInformation();
    this.headerFilterInfo$ = this.layoutService.getHeaderFilterInformation();

    this.navItems$ = combineLatest([
      this.routerService.isCurrentRouteSandBoxed(),
      this.microAppService.getMicroAppNavigation(),
    ]).pipe(
      map(([isSandBoxed, microAppNavigation]) => {
        if (isSandBoxed) {
          return onboardingNavItems;
        } else {
          return microAppNavigation;
        }
      }),
    );

    this.microAppBasePath$ = this.microAppService.getMicroAppBasePath();
    this.microAppDisplayName$ = this.microAppService.getMicroAppDisplayName();
    this.microAppList$ = combineLatest([
      this.microAppService.getMicroAppListState(),
      this.authGroupService.getAllMy(),
    ]).pipe(
      map(([list, authGroups]) => {
        const groupNames = authGroups.map((group) => group.name);
        if (
          !(
            groupNames.includes(AUTH_GROUPS.CONTROL_ADMIN_USER) ||
            groupNames.includes(AUTH_GROUPS.CONTROL_INTERMEDIATE) ||
            groupNames.includes(AUTH_GROUPS.CONTROL_READ_ONLY)
          )
        ) {
          const index = list.findIndex((item) => item.code === 'control');
          if (index !== -1) {
            list.splice(index, 1);
          }
        }

        return list.filter(({ code }) => code !== PRODUCT_CODES.AGENCY);
      }),
    );

    this.isAgencyPlatform$ = this.microAppService.isAgencyPlatform();
    this.hospitalOfficerPath$ = this.microAppService.getAppPath('hospital-officer');

    this.serverName = getServerName();

    this.hasUnreadMessages$ = this.isAgencyPlatform$.pipe(
      switchMap((isAgencyPlatform) =>
        isAgencyPlatform ? of(false) : this.zendeskRequestService.hasUnreadMessages(),
      ),
    );
  }

  ngOnInit() {
    this.store.dispatch(new InitializeLayoutContainerSignal({}));
    this.store.dispatch(new CheckForNotificationsPermissionSignal({}));
    this.addToSubscriptions([
      this.routerService
        .getCurrentPath()
        .pipe(mergeMap((path) => this.microAppService.setMicroAppConfiguration(path)))
        .subscribe(),
      this.layoutService.initializeChameleon().subscribe(),
      this.message$.subscribe(),
    ]);

    this.message$ = this.microAppService.isAgencyPlatform().pipe(
      switchMap((isAgencyPlatform) => {
        if (isAgencyPlatform) return EMPTY;
        return from(isSupported()).pipe(
          switchMap((supported: boolean) => {
            if (!supported) return EMPTY;
            return new Observable((sub) =>
              onMessage(this.messaging, (msg) => sub.next(msg.data)),
            ).pipe(tap((msg: FireBaseMessage) => this.refetchRequestAndCommentsSignals(msg)));
          }),
        );
      }),
    );

    isSupported().then((supported: boolean) => {
      if (supported) {
        navigator.serviceWorker.addEventListener('message', (swMsg) => {
          if (!swMsg.data.data) this.refetchRequestAndCommentsSignals(swMsg.data);
        });
      }
    });
  }

  refetchRequestAndCommentsSignals(msg: FireBaseMessage) {
    this.store.dispatch(new FetchRequestByIdSignal({ id: +msg.ticket_id, showNotification: true }));
    this.store.dispatch(new GetCommentsForRequestSignal({ requestId: +msg.ticket_id }));
  }

  onMessagingMenuClicked() {
    this.store.dispatch(new ShowMessagingPanelSignal({}));
  }

  onToggleFiltersPanel() {
    this.store.dispatch(new ToggleFiltersPanelSignal({}));
  }

  toggleProductPanel() {
    this.store.dispatch(new ToggleProductPanelSignal({}));
  }

  onOptionClicked() {
    this.responsiveMenuOpen = false;
    this.layoutService.getShowProductPanel().pipe(
      map((showProductPanel) => {
        if (showProductPanel) {
          this.toggleProductPanel();
        }
      }),
    );
  }

  onMenuExpanded(expanded: boolean) {
    this.menuExpanded = expanded;
  }

  onResponsiveMenuOpen() {
    this.responsiveMenuOpen = !this.responsiveMenuOpen;
  }

  public onAlertToastClicked() {
    this.layoutService.resetAlert();
  }

  onPersonalDetailsClicked() {
    this.store.dispatch(new ShowHideHeaderFilterInformationSignal({}));
  }

  changeProduct(newProduct: IMicroAppListItemState) {
    this.responsiveMenuOpen = false;
    this.chameleonService.trackProductChange(newProduct.display);
    this.store.dispatch(new ShowProductPanelSignal({ show: false }));
    this.store.dispatch(new ShowMessagingPanelSignal({}));
    this.store.dispatch(new InitializeLayoutContainerSignal({}));
    this.router.navigateByUrl(newProduct.basePath);
  }

  logout() {
    this.router.navigateByUrl('/logout');
  }
}
