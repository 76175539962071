import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { UpdateEntityStatePreferredSpecialtyCategorySignal } from '../../preferred-specialty-category/+state/preferred-specialty-category.signals';
import { UpdateEntityStateMessage } from './preferred-specialty-category.messages';
import { selectPreferredSpecialtyCategoryTempState } from './preferred-specialty-category.selectors';

@Injectable()
export class PreferredSpecialtyCategoryEffects {
  updateEntityStatePreferredSpecialtyCategorySignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateEntityStatePreferredSpecialtyCategorySignal>(
        UpdateEntityStatePreferredSpecialtyCategorySignal.TYPE
      ),
      concatLatestFrom(() => this.store.pipe(select(selectPreferredSpecialtyCategoryTempState))),
      map(
        ([_, preferredSpecialtyCategoryTempState]) =>
          new UpdateEntityStateMessage({
            preferredSpecialtyCategoryTempState,
          })
      )
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}
