import * as fromRouter from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, combineReducers, MetaReducer } from '@ngrx/store';

import { environment } from '../../environments/environment';
import * as fromAuthGroup from '../auth-group/+state/auth-group.reducer';
import * as fromCertificateType from '../certificate-type/+state/certificate-type.reducer';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromLayout from '../core/+state';
import * as fromDeclineApplicationReason from '../decline-application-reason/+state/decline-application-reason.reducer';
import * as fromExternalStaffingCandidateBid from '../external-staffing-candidate-bid/+state/external-staffing-candidate-bid.reducer';
import * as fromExternalStaffingProviderOfficer from '../external-staffing-provider-officer/+state';
import * as fromExternalStaffingProvider from '../external-staffing-provider/+state/external-staffing-provider.reducer';
import * as fromGradeRate from '../grade-rate/+state/grade-rate.reducer';
import * as fromGrade from '../grade/+state/grade.reducer';
import * as fromHospitalOfficerSite from '../hospital-officer-site/+state/hospital-officer-site.reducer';
import * as fromHospitalSpecialty from '../hospital-specialty/+state/hospital-specialty.reducer';
import * as fromHospitalVacancyReason from '../hospital-vacancy-reason/+state/hospital-vacancy-reason.reducer';
import * as fromHospital from '../hospital/+state/hospital.reducer';
import * as fromIntegration from '../integration/+state/integration.reducer';
import * as fromJobListingConversationProfile from '../job-listing-conversation-profile/+state/job-listing-conversation-profile.reducer';
import * as fromJobListing from '../job-listing/+state/job-listing.reducer';
import * as fromLinkColumnChoice from '../link-column-choice/+state/link-column-choice.reducer';
import * as fromLinkTemplate from '../link-template/+state/link-template.reducer';
import * as fromPayRateType from '../pay-rate-type/+state/pay-rate-type.reducer';
import * as fromPensionConfiguration from '../pension-configuration/+state/pension-configuration.reducer';
import * as fromPermission from '../permission/+state/permission.reducer';
import * as fromPrefPassportProfession from '../preferred-passport-profession/+state/preferred-passport-profession.reducer';
import * as fromPrefProfSpecialty from '../preferred-profession-specialty/+state/preferred-profession-specialty.reducer';
import * as fromPreferredProfession from '../preferred-profession/+state/preferred-profession.reducer';
import * as fromPreferredRoster from '../preferred-roster/+state/preferred-roster.reducer';
import * as fromPreferredSite from '../preferred-site/+state/preferred-site.reducer';
import * as fromPrefSpecialtyCategory from '../preferred-specialty-category/+state/preferred-specialty-category.reducer';
import * as fromPreferredWard from '../preferred-ward/+state/preferred-ward.reducer';
import * as fromProfessionSpecialty from '../profession-specialty/+state/profession-specialty.reducer';
import * as fromProfession from '../profession/+state/profession.reducer';
import * as fromProfile from '../profile/+state/profile.reducer';
import * as fromRevalidationStatus from '../revalidation-status/+state/revalidation-status.reducer';
import * as fromRoster from '../roster/+state/roster.reducer';
import * as fromSite from '../site/+state/site.reducer';
import * as fromSpecialty from '../specialty/+state/specialty.reducer';
import * as fromStaffBankMembershipRequest from '../staff-bank-membership-request/+state/staff-bank-membership-request.reducer';
import * as fromStaffingCascadeTimeWindow from '../staffing-cascade-time-window/+state/staffing-cascade-time-window.reducer';
import * as fromStaffingCascade from '../staffing-cascade/+state/staffing-cascade.reducer';
import * as fromSubSpecialty from '../sub-specialty/+state/sub-specialty.reducer';
import * as fromTimeSheetStatus from '../time-sheet-status/+state/time-sheet-status.reducer';
import * as fromTrustOnboardingProcess from '../trust-onboarding-process/+state/interfaces';
import * as fromVacancyReason from '../vacancy-reason/+state/vacancy-reason.reducer';
import * as fromWard from '../ward/+state/ward.reducer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  layout: fromLayout.State;
  router: fromRouter.RouterReducerState;
  sites: fromSite.ISiteSharedState;
  payRateTypes: fromPayRateType.State;
  gradeRates: fromGradeRate.State;
  specialties: fromSpecialty.State;
  subSpecialties: fromSubSpecialty.State;
  grades: fromGrade.State;
  vacancyReasons: fromVacancyReason.State;
  externalStaffingProviders: fromExternalStaffingProvider.State;
  staffingCascadeTimeWindows: fromStaffingCascadeTimeWindow.IStaffingCascadeTimeWindowSharedState;
  permissions: fromPermission.IPermissionSharedState;
  timeSheetStatuses: fromTimeSheetStatus.State;
  hospitals: fromHospital.IHospitalSharedState;
  preferredSites: fromPreferredSite.IPreferredSiteSharedState;
  preferredWards: fromPreferredWard.IPreferredWardSharedState;
  preferredRosters: fromPreferredRoster.IPreferredRosterSharedState;
  preferredProfessionSpecialties: fromPrefProfSpecialty.IPreferredProfessionSpecialtySharedState;
  hospitalOfficerSites: fromHospitalOfficerSite.IHospitalOfficerSiteSharedState;
  certificateTypes: fromCertificateType.ICertificateTypeSharedState;
  hospitalVacancyReasons: fromHospitalVacancyReason.IHospitalVacancyReasonSharedState;
  hospitalSpecialties: fromHospitalSpecialty.IHospitalSpecialtySharedState;
  pensionConfigurations: fromPensionConfiguration.IPensionConfigurationSharedState;
  revalidationStatuses: fromRevalidationStatus.IRevalidationStatusSharedState;
  declineApplicationReasons: fromDeclineApplicationReason.IDeclineApplicationReasonSharedState;
  professions: fromProfession.IProfessionSharedState;
  professionSpecialties: fromProfessionSpecialty.IProfessionSpecialtySharedState;
  preferredSpecialtyCategories: fromPrefSpecialtyCategory.IPreferredSpecialtyCategorySharedState;
  preferredProfessions: fromPreferredProfession.IPreferredProfessionSharedState;
  preferredPassportProfessions: fromPrefPassportProfession.IPreferredPassportProfessionSharedState;
  authGroups: fromAuthGroup.IAuthGroupSharedState;
  linkTemplates: fromLinkTemplate.ILinkTemplateSharedState;
  linkColumnChoices: fromLinkColumnChoice.ILinkColumnChoiceSharedState;
  // eslint-disable-next-line max-len
  externalStaffingProviderOfficers: fromExternalStaffingProviderOfficer.IExternalStaffingProviderOfficerSharedState;
  wards: fromWard.IWardSharedState;
  rosters: fromRoster.IRosterSharedState;
}
//@todo check if used and consider using feature state interface directly
// to discontinue this
export interface AppState extends State {
  jobListing: fromJobListing.State;
  externalStaffingCandidateBid: fromExternalStaffingCandidateBid.State;
  profile: fromProfile.State;
  staffingCascades: fromStaffingCascade.State;
  staffBankMembershipRequest: fromStaffBankMembershipRequest.State;
  trustOnboardingProcess: fromTrustOnboardingProcess.State;
  integration: fromIntegration.State;
  jobListingConversationProfile: fromJobListingConversationProfile.State;
}
/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  authGroups: fromAuthGroup.reducer,
  certificateTypes: fromCertificateType.reducer,
  declineApplicationReasons: fromDeclineApplicationReason.reducer,
  externalStaffingProviderOfficers: combineReducers(fromExternalStaffingProviderOfficer.reducers),
  externalStaffingProviders: fromExternalStaffingProvider.reducer,
  gradeRates: fromGradeRate.reducer,
  grades: fromGrade.reducer,
  hospitalOfficerSites: fromHospitalOfficerSite.reducer,
  hospitals: fromHospital.reducer,
  hospitalSpecialties: fromHospitalSpecialty.reducer,
  hospitalVacancyReasons: fromHospitalVacancyReason.reducer,
  layout: fromLayout.reducer,
  linkColumnChoices: fromLinkColumnChoice.reducer,
  linkTemplates: fromLinkTemplate.reducer,
  payRateTypes: fromPayRateType.reducer,
  pensionConfigurations: fromPensionConfiguration.reducer,
  permissions: fromPermission.reducer,
  preferredPassportProfessions: fromPrefPassportProfession.reducer,
  preferredProfessions: fromPreferredProfession.reducer,
  preferredProfessionSpecialties: fromPrefProfSpecialty.reducer,
  preferredRosters: fromPreferredRoster.reducer,
  preferredSites: fromPreferredSite.reducer,
  preferredSpecialtyCategories: fromPrefSpecialtyCategory.reducer,
  preferredWards: fromPreferredWard.reducer,
  professions: fromProfession.reducer,
  professionSpecialties: fromProfessionSpecialty.reducer,
  revalidationStatuses: fromRevalidationStatus.reducer,
  rosters: fromRoster.reducer,
  router: fromRouter.routerReducer,
  sites: fromSite.reducer,
  specialties: fromSpecialty.reducer,
  staffingCascadeTimeWindows: fromStaffingCascadeTimeWindow.reducer,
  subSpecialties: fromSubSpecialty.reducer,
  timeSheetStatuses: fromTimeSheetStatus.reducer,
  vacancyReasons: fromVacancyReason.reducer,
  wards: fromWard.reducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: any): State {
    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger] : [];
