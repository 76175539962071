import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { UpdateEntityStateMessage } from './preferred-site.messages';
import { selectPreferredSiteTempState } from './preferred-site.selectors';
import { UpdateEntityStatePreferredSiteSignal } from './preferred-site.signals';

@Injectable()
export class PreferredSiteEffects {
  updateEntityStatePreferredSiteSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateEntityStatePreferredSiteSignal>(UpdateEntityStatePreferredSiteSignal.TYPE),
      concatLatestFrom(() => this.store.pipe(select(selectPreferredSiteTempState))),
      map(([_, preferredSiteTempState]) => new UpdateEntityStateMessage({ preferredSiteTempState }))
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}
