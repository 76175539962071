import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProfessionService } from 'apps/hospital-admin/src/app/profession/+state/profession.service';
import { concat, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap } from 'rxjs/operators';

import {
  IPreferredProfessionSpecialtyEntity,
  IPreferredProfessionSpecialtyPostEntity,
} from '../../../interfaces/api/preferred-profession-specialty-entity';
import { PreferredSpecialtyCategoryService } from '../../../preferred-specialty-category/+state/preferred-specialty-category.service';
import { SubSpecialtyService } from '../../../sub-specialty/+state/sub-specialty.service';
import { conditionalErrorHandler } from '../preferred-profession-specialty.adapter';
import { PreferredProfessionSpecialtyPersistenceService } from '../preferred-profession-specialty.persistence.service';
import { PreferredProfessionSpecialtyService } from '../preferred-profession-specialty.service';
import { AddOneMessage, DeleteOneMessage } from './preferred-profession-specialty.messages';
import {
  AddSelectedPreferredProfessionSpecialtyTempSignal,
  RemoveSelectedPreferredProfessionSpecialtyTempSignal,
} from './preferred-profession-specialty.signals';

@Injectable()
export class PreferredProfessionSpecialtyTempEffects {
  addSelectedPreferredProfessionSpecialtyTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddSelectedPreferredProfessionSpecialtyTempSignal>(
        AddSelectedPreferredProfessionSpecialtyTempSignal.TYPE
      ),
      mergeMap((action) => {
        const { payload } = action as AddSelectedPreferredProfessionSpecialtyTempSignal;

        const data: IPreferredProfessionSpecialtyPostEntity = {
          professionSpecialty: payload.id,
        };
        return concat(
          this.persistenceService
            .create<IPreferredProfessionSpecialtyPostEntity, IPreferredProfessionSpecialtyEntity>(
              data
            )
            .pipe(
              map(
                (response: IPreferredProfessionSpecialtyEntity) =>
                  new AddOneMessage({
                    entity: response,
                  })
              ),
              catchError(
                conditionalErrorHandler({
                  errorEventMessageHandler: (message) =>
                    `An error occurred while selected the site: ${message}`,
                  errorDetailMessageHandler: (message) =>
                    `Sorry! SubSpecialty can’t be selected. The error was: ${message}`,
                  unknownErrorMessage:
                    'Sorry! SubSpecialty can’t be selected. Please try again in a few minutes',
                })
              )
            ),
          this.professionService.getProfessionSpecialtyEntities().pipe(
            // eslint-disable-next-line ngrx/no-multiple-actions-in-effects
            mergeMap((entities) => entities),
            filter(({ id }) => id === payload.id),
            first(),
            mergeMap(({ specialty, profession }) =>
              this.preferredSpecialtyCategoryService.addSpecialtyCategoryIfAllSubSpecialtiesSelected(
                specialty.category,
                profession.id
              )
            )
          )
        );
      })
    )
  );

  removeSelectedPreferredProfessionSpecialtyTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveSelectedPreferredProfessionSpecialtyTempSignal.TYPE),
      concatLatestFrom(() => this.preferredProfessionSpecialtyService.getAllTempWithSpecialty()),
      mergeMap(([action, preferredProfessionSpecialties]) => {
        const { payload } = action as RemoveSelectedPreferredProfessionSpecialtyTempSignal;

        const preferredProfessionSpecialty = preferredProfessionSpecialties
          ? preferredProfessionSpecialties.find((x) => x.professionSpecialty.id === payload.id)
          : null;

        return this.persistenceService.delete(preferredProfessionSpecialty.id).pipe(
          mergeMap(() => {
            const { professionSpecialty } = preferredProfessionSpecialty;
            return this.subSpecialtyService.getOne(professionSpecialty.specialty).pipe(
              mergeMap((specialty) =>
                concat(
                  this.preferredSpecialtyCategoryService.deleteSpecialtyCategory(
                    specialty.category,
                    professionSpecialty.profession
                  ),
                  of(
                    new DeleteOneMessage({
                      id: preferredProfessionSpecialty.id,
                    })
                  )
                )
              )
            );
          }),
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while unselected the site: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! SubSpecialty can’t be unselected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! SubSpecialty can’t be unselected. Please try again in a few minutes',
            })
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private persistenceService: PreferredProfessionSpecialtyPersistenceService,
    private subSpecialtyService: SubSpecialtyService,
    private preferredSpecialtyCategoryService: PreferredSpecialtyCategoryService,
    private preferredProfessionSpecialtyService: PreferredProfessionSpecialtyService,
    private professionService: ProfessionService
  ) {}
}
