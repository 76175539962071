<div class="page-parent">
  <div class="header-container {{ serverName | lowercase }}" #headerContainer>
    <div class="header-left">
      <a [routerLink]="['/' + (this.microAppBasePath$ | async)]">
        <div class="owl-logo"></div>
        <div class="owl"></div>
      </a>
      <div class="name" [id]="idPageName + '__micro-app-name'">
        {{ microAppDisplayName$ | async }}
        <div>
          @if (serverName) {
            @if (nextShutDown$ | async; as nextShutDown) {
              <p class="stage-name" [tp]="nextShutDownTpl" [tpData]="nextShutDown$ | async">
                &nbsp; ( {{ serverName }} )
              </p>
            } @else {
              <p class="stage-name">&nbsp; ( {{ serverName }} )</p>
            }
          }

          <ng-template #nextShutDownTpl let-item="data">
            This environment will be disabled on {{ item | date: 'full' }}
          </ng-template>
        </div>
      </div>
    </div>
    <div class="header-right">
      <div class="inside">
        <div
          class="personal-details"
          #personalDetails
          [id]="idPageName + '__personal-details'"
          [ngClass]="{
            disabled: !(headerFilterInfo$ | async)?.isClickable
          }"
          (click)="onPersonalDetailsClicked()"
        >
          <ng-container *ngIf="officerPersonalDetails$ | async">
            <div
              *ngIf="showHeaderFilterInformation$ | async; else personalInformation"
              class="preferred-specialties"
            >
              @if ((headerFilterInfo$ | async)?.selectedFilters; as specialties) {
                @if (specialties.length) {
                  <div class="preferred-specialties__container">
                    @for (specialty of specialties; track specialty) {
                      <ng-container *ngIf="$index < 5">
                        <!--the index start from 0-->
                        <div
                          class="specialty-separator"
                          #separator
                          [style.visibility]="$index !== 0 ? 'visible' : 'hidden'"
                        >
                          ,&nbsp;
                        </div>
                        <div
                          class="specialty-name"
                          [id]="idPageName + '__' + specialty"
                          [title]="specialties"
                          [style.max-width.px]="
                            (personalDetails.offsetWidth -
                              75 -
                              separator.offsetWidth *
                                (specialties.length > 5 ? 5 : specialties.length - 1)) /
                            (specialties.length > 5 ? 5 : specialties.length)
                          "
                        >
                          {{ specialty }}
                        </div>
                      </ng-container>
                    }

                    <ng-container *ngIf="(headerFilterInfo$ | async)?.selectedFilters.length > 5">
                      <div>+{{ (headerFilterInfo$ | async)?.selectedFilters.length - 5 }} more</div>
                    </ng-container>
                  </div>
                } @else {
                  {{ (headerFilterInfo$ | async)?.emptyText }}
                }
              }
            </div>
          </ng-container>
        </div>
        <div class="sign-out">
          <div [id]="idPageName + '__logout'" (click)="logout()">
            <div class="sign-out-icon"></div>
          </div>
        </div>

        <div class="menu-trigger" (click)="onResponsiveMenuOpen()">
          <div class="burger-menu"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="layout-container">
  <div class="side-nav-bar">
    <locumsnest-side-nav-bar
      class="left-nav-bar"
      [idx]="idPageName"
      [itemList]="navItems$ | async"
      [filterCount]="filterCount$ | async"
      [nestPanelExpanded]="showProductPanel$ | async"
      [currentUrl]="currentUrl$ | async"
      [hospitalOfficerPath]="hospitalOfficerPath$ | async"
      [isAgencyPlatform]="isAgencyPlatform$ | async"
      [showMessagingPanel]="showMessagingPanel$ | async"
      [hasUnreadMessages]="hasUnreadMessages$ | async"
      (toggle)="toggleProductPanel()"
      (clicked)="onOptionClicked()"
      (expanded)="onMenuExpanded($event)"
      (filterMenuClicked)="onToggleFiltersPanel()"
      (messagingMenuClicked)="onMessagingMenuClicked()"
    >
      <div
        *ngIf="showProductPanel$ | async"
        class="side-nav-bar-content"
        [@dropDownMenu]
        [ngClass]="{ 'menu-expanded': menuExpanded }"
      >
        <div class="nest">
          <div class="arrow-menu">
            <div
              class="nest-icon"
              [ngClass]="{ 'arrow-expanded': menuExpanded }"
              (click)="toggleProductPanel()"
            >
              <div class="menu-arrow"></div>
            </div>
          </div>
          @for (microApp of microAppList$ | async; track microApp.code) {
            <div
              class="nest-item"
              [id]="idPageName + '__side-menu__' + microApp.code"
              [ngClass]="{ expanded: menuExpanded }"
              (click)="changeProduct(microApp)"
            >
              <div class="nest-icon">
                <div
                  class="icon"
                  [ngClass]="microApp.code + (microApp.isActive ? '-icon--active' : '-icon')"
                ></div>
                <span *ngIf="!menuExpanded">{{ microApp.display }}</span>
              </div>
              <div *ngIf="menuExpanded" class="product-name">{{ microApp.display }}</div>
            </div>
          }
        </div>
      </div>
    </locumsnest-side-nav-bar>
  </div>

  <div *ngIf="responsiveMenuOpen" class="side-menu">
    <div class="menu-close" (click)="onResponsiveMenuOpen()"></div>
    <locumsnest-side-nav-bar
      class="left-nav-bar"
      [idx]="idPageName"
      [itemList]="navItems$ | async"
      [filterCount]="filterCount$ | async"
      [nestPanelExpanded]="showProductPanel$ | async"
      [currentUrl]="currentUrl$ | async"
      [hospitalOfficerPath]="hospitalOfficerPath$ | async"
      [isAgencyPlatform]="isAgencyPlatform$ | async"
      [showMessagingPanel]="showMessagingPanel$ | async"
      [hasUnreadMessages]="hasUnreadMessages$ | async"
      (toggle)="toggleProductPanel()"
      (clicked)="onOptionClicked()"
      (expanded)="onMenuExpanded($event)"
      (filterMenuClicked)="onToggleFiltersPanel()"
      (messagingMenuClicked)="onMessagingMenuClicked()"
    >
      <div
        *ngIf="showProductPanel$ | async"
        class="side-nav-bar-content menu-expanded"
        [@dropDownMenu]
      >
        <div class="nest">
          @for (microApp of microAppList$ | async; track microApp.code) {
            <div
              class="nest-item expanded"
              [id]="idPageName + '__side-menu__' + microApp.code"
              (click)="changeProduct(microApp)"
            >
              <div class="nest-icon">
                <div
                  class="icon"
                  [ngClass]="microApp.code + (microApp.isActive ? '-icon--active' : '-icon')"
                ></div>
              </div>
              <div class="product-name">{{ microApp.display }}</div>
            </div>
          }
        </div>
      </div>
    </locumsnest-side-nav-bar>
  </div>

  <div id="page-container" class="page-container">
    <div class="scroller">
      <div *ngIf="alertState$ | async as alertState">
        <locumsnest-toast
          [alertType]="alertState.type"
          [message]="alertState.message"
          [displayAlert]="alertState.displayAlertMessage"
          (toastClicked)="onAlertToastClicked()"
        />
      </div>
      <router-outlet name="common-component"></router-outlet>
      <div *ngIf="showFiltersPanel$ | async" class="overlay">
        <router-outlet name="filter-panel"></router-outlet>
      </div>
      <div>
        <router-outlet name="messaging-panel"></router-outlet>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #personalInformation>
  <div class="ellipsis organization-details-name" [title]="'See selected departments'">
    <ng-container *ngIf="officerPersonalDetails$ | async as officerPersonalDetails">
      <span [id]="idPageName + '__officer-personal-details'">
        {{ officerPersonalDetails.firstName + ' ' + officerPersonalDetails.lastName }}
      </span>
    </ng-container>
    <span>&#64;</span>
    <span [id]="idPageName + '__organization-details-name'">
      {{ organizationDetailsName$ | async }}
    </span>
  </div>
</ng-template>

<ng-container *ngIf="message$ | async"></ng-container>
