import { combineReducers } from '@ngrx/store';

import { formReducer } from '../../messaging-panel/+state/messaging-form';
import { reducer as messagingUiReducer } from '../../messaging-panel/+state/messaging-ui';
import { reducer as uiReducer } from './ui';

export const reducer = combineReducers({
  uiState: uiReducer,
  messagingFormState: formReducer,
  messagingUiState: messagingUiReducer,
});

export * from './ui';
export * from './interfaces';
