import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { ErrorInterceptor } from '@locumsnest/auth/src/lib/session-auth/services';
import { AuthService } from '@locumsnest/auth/src/lib/session-auth/services/auth.service';

import { SimpleHomePageService } from './services/home-page.service';
import { IHomePageService } from './services/interfaces';
import { HOME_PAGE_SERVICE } from './services/opaque-tokens';

export type AuthModulePublicProviders = [{ provide: InjectionToken<IHomePageService> } & Provider];
const DEFAULT_PUBLIC_PROVIDERS: AuthModulePublicProviders = [
  {
    provide: HOME_PAGE_SERVICE,
    useClass: SimpleHomePageService,
  },
];

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class SessionAuthModule {
  static forRoot<T>(
    publicProviders: AuthModulePublicProviders = DEFAULT_PUBLIC_PROVIDERS,
  ): ModuleWithProviders<SessionAuthModule> {
    return {
      ngModule: SessionAuthModule,
      providers: [
        ...publicProviders,
        AuthService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true,
        },
      ],
    };
  }
}
