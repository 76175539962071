import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, concat, merge, of } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';

import { UpdateOneMessage } from '../../../hospital-officer/+state';
import { HospitalOfficerPersistenceService } from '../../../hospital-officer/+state/hospital-officer.persistence.service';
import { HospitalOfficerService } from '../../../hospital-officer/+state/hospital-officer.service';
import {
  IHospitalOfficerEntity,
  IHospitalOfficerUpdateEntity,
} from '../../../interfaces/api/hospital-officer-entity';
import {
  IPreferredWardEntity,
  IPreferredWardPostEntity,
} from '../../../interfaces/api/preferred-ward-entity';
import { PreferredRosterService } from '../../../preferred-roster/+state/preferred-roster.service';
import { RosterService } from '../../../roster/+state/roster.service';
import { WardService } from '../../../ward/+state/ward.service';
import { conditionalErrorHandler } from '../preferred-ward.adapter';
import { PreferredWardPersistenceService } from '../preferred-ward.persistence.service';
import { PreferredWardService } from '../preferred-ward.service';
import {
  SelectAllCompleteMessage,
  SelectAllInProgressMessage,
} from './../../../core/+state/filter-ui/filter-ui.messages';
import {
  AddOneMessage,
  DeleteOneMessage,
  SetSelectUnassignedMessage,
} from './preferred-ward-temp.messages';
import {
  AddSelectedPreferredWardTempSignal,
  ClearAllPreferredWardsTempSignal,
  RemoveSelectedPreferredWardTempSignal,
  SelectAllPreferredWardsTempSignal,
} from './preferred-ward-temp.signals';

@Injectable()
export class PreferredWardTempEffects {
  addSelectedPreferredWardTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddSelectedPreferredWardTempSignal>(AddSelectedPreferredWardTempSignal.TYPE),
      concatLatestFrom(() => [
        this.wardService.getAll(),
        this.rosterService.getAll(),
        this.preferredRosterService.getAllTemp(),
        this.hospitalOfficerService.getAssigned(),
      ]),
      mergeMap(([action, wards, rosters, preferredRosters, hospitalOfficer]) => {
        const { payload } = action as AddSelectedPreferredWardTempSignal;

        if (wards.find((ward) => ward.id === payload.id)) {
          const data: IPreferredWardPostEntity = {
            ward: payload.id,
          };

          const rosterIds = rosters
            .filter((roster) => roster.ward === payload.id)
            .map((roster) => roster.id);
          const preferredRosterIds = preferredRosters
            .filter((roster) => roster.ward === payload.id)
            .map((roster) => roster.roster);

          const unSelectedRosterIds = rosterIds.filter(
            (roster) => !preferredRosterIds.includes(roster)
          );

          const unSelectedRosters = unSelectedRosterIds.map((roster) => ({
            ward: payload.id,
            roster,
          }));

          return concat(
            this.persistenceService
              .create<IPreferredWardPostEntity, IPreferredWardEntity>(data)
              .pipe(
                mergeMap((response: IPreferredWardEntity) =>
                  merge(
                    of(
                      new AddOneMessage({
                        entity: response,
                      })
                    ),
                    this.preferredRosterService.bulkCreate(unSelectedRosters)
                  )
                ),
                catchError(
                  conditionalErrorHandler({
                    errorEventMessageHandler: (message) =>
                      `An error occurred while selected the ward: ${message}`,
                    errorDetailMessageHandler: (message) =>
                      `Sorry! Ward can’t be selected. The error was: ${message}`,
                    unknownErrorMessage:
                      'Sorry! Ward can’t be selected. Please try again in a few minutes',
                  })
                )
              )
          );
        } else {
          hospitalOfficer = {
            ...hospitalOfficer,
            prefersUnassignedWards: true,
          };

          return this.hospitalOfficerPersistenceService
            .patch<IHospitalOfficerUpdateEntity>(hospitalOfficer.id, { ...hospitalOfficer })
            .pipe(
              mergeMap((response: IHospitalOfficerEntity) =>
                merge(
                  of(
                    new SetSelectUnassignedMessage({
                      selectUnassigned: true,
                    })
                  ),
                  of(
                    new UpdateOneMessage({
                      entity: {
                        id: response.id,
                        changes: response,
                      },
                    })
                  )
                )
              ),
              catchError(conditionalErrorHandler())
            );
        }
      })
    )
  );

  removeSelectedPreferredWardTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveSelectedPreferredWardTempSignal>(RemoveSelectedPreferredWardTempSignal.TYPE),
      concatLatestFrom(() => [
        this.wardService.getAll(),
        this.hospitalOfficerService.getAssigned(),
      ]),
      mergeMap(([action, wards, hospitalOfficer]) => {
        const { payload } = action as RemoveSelectedPreferredWardTempSignal;

        if (wards.find((ward) => ward.id === payload.id)) {
          return combineLatest([
            this.preferredWardService.getPreferredWardByWardId(payload.id),
            this.preferredRosterService.getAllTempIdsByWard(payload.id),
          ]).pipe(
            first(),
            mergeMap(([preferredWard, rosterIds]) =>
              this.persistenceService.delete(preferredWard.id).pipe(
                mergeMap(() =>
                  merge(
                    of(
                      new DeleteOneMessage({
                        id: preferredWard.id,
                      })
                    ),
                    this.preferredRosterService.bulkDelete(rosterIds)
                  )
                ),
                catchError(
                  conditionalErrorHandler({
                    errorEventMessageHandler: (message) =>
                      `An error occurred while unselected the ward: ${message}`,
                    errorDetailMessageHandler: (message) =>
                      `Sorry! Ward can’t be unselected. The error was: ${message}`,
                    unknownErrorMessage:
                      'Sorry! Ward can’t be unselected. Please try again in a few minutes',
                  })
                )
              )
            )
          );
        } else {
          hospitalOfficer = {
            ...hospitalOfficer,
            prefersUnassignedWards: false,
          };

          return this.hospitalOfficerPersistenceService
            .patch<IHospitalOfficerUpdateEntity>(hospitalOfficer.id, { ...hospitalOfficer })
            .pipe(
              mergeMap((response: IHospitalOfficerEntity) =>
                merge(
                  of(
                    new SetSelectUnassignedMessage({
                      selectUnassigned: false,
                    })
                  ),
                  of(
                    new UpdateOneMessage({
                      entity: {
                        id: response.id,
                        changes: response,
                      },
                    })
                  )
                )
              ),
              catchError(conditionalErrorHandler())
            );
        }
      })
    )
  );

  selectAllPreferredWardsTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectAllPreferredWardsTempSignal>(SelectAllPreferredWardsTempSignal.TYPE),
      concatLatestFrom(() => [
        this.wardService.getAll(),
        this.preferredWardService.getAllTemp(),
        this.rosterService.getAll(),
        this.preferredRosterService.getTempRosters(),
        this.hospitalOfficerService.getAssigned(),
      ]),
      mergeMap(([_, wards, preferredWards, rosters, preferredRosters, hospitalOfficer]) => {
        const wardIds = wards.map((ward) => ward.id);
        const preferredWardIds = preferredWards.map((ward) => ward.ward);

        const unSelectedWardIds = wardIds.filter((ward) => !preferredWardIds.includes(ward));
        const unSelectedWardPost = unSelectedWardIds.map((ward) => ({ ward }));

        const unSelectedRosters = [];

        hospitalOfficer = {
          ...hospitalOfficer,
          prefersUnassignedWards: true,
        };

        for (const ward of wards) {
          const rosterIds = rosters
            .filter((roster) => roster.ward === ward.id)
            .map((roster) => roster.id);

          const preferredRosterIds = preferredRosters
            .filter((roster) => roster.ward === ward.id)
            .map((roster) => roster.id);

          const unSelectedRosterIds = rosterIds.filter(
            (roster) => !preferredRosterIds.includes(roster)
          );

          unSelectedRosterIds.map((roster) =>
            unSelectedRosters.push({
              ward: ward.id,
              roster,
            })
          );
        }

        const unassignedRosterIds = rosters.filter((roster) => roster.ward === null);

        unassignedRosterIds.map((roster) =>
          unSelectedRosters.push({
            roster: roster.id,
          })
        );

        const actions$ = merge(
          this.preferredWardService.bulkCreate(unSelectedWardPost).pipe(
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: (message) =>
                  `An error occurred while selecting the wards: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Wards can’t be selected. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Wards can’t be selected. Please try again in a few minutes',
              })
            )
          ),
          this.hospitalOfficerPersistenceService
            .patch<IHospitalOfficerUpdateEntity>(hospitalOfficer.id, { ...hospitalOfficer })
            .pipe(
              mergeMap((response: IHospitalOfficerEntity) =>
                merge(
                  of(
                    new SetSelectUnassignedMessage({
                      selectUnassigned: true,
                    })
                  ),
                  of(
                    new UpdateOneMessage({
                      entity: {
                        id: response.id,
                        changes: response,
                      },
                    })
                  )
                )
              ),
              catchError(conditionalErrorHandler())
            ),
          this.preferredRosterService.bulkCreate(unSelectedRosters)
        );

        return concat(
          of(new SelectAllInProgressMessage({})),
          actions$,
          of(new SelectAllCompleteMessage({}))
        );
      })
    )
  );

  clearAllPreferredWardsTempSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ClearAllPreferredWardsTempSignal>(ClearAllPreferredWardsTempSignal.TYPE),
      concatLatestFrom(() => this.hospitalOfficerService.getAssigned()),
      mergeMap(([_, hospitalOfficer]) => {
        hospitalOfficer = {
          ...hospitalOfficer,
          prefersUnassignedWards: false,
        };

        return merge(
          this.preferredWardService.clear(),
          this.hospitalOfficerPersistenceService
            .patch<IHospitalOfficerUpdateEntity>(hospitalOfficer.id, { ...hospitalOfficer })
            .pipe(
              mergeMap((response: IHospitalOfficerEntity) =>
                merge(
                  of(
                    new SetSelectUnassignedMessage({
                      selectUnassigned: false,
                    })
                  ),
                  of(
                    new UpdateOneMessage({
                      entity: {
                        id: response.id,
                        changes: response,
                      },
                    })
                  )
                )
              ),
              catchError(conditionalErrorHandler())
            ),
          this.preferredRosterService.clear()
        ).pipe(
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while unselecting the wards: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Wards can’t be unselected. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Wards can’t be unselected. Please try again in a few minutes',
            })
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private persistenceService: PreferredWardPersistenceService,
    private wardService: WardService,
    private rosterService: RosterService,
    private preferredWardService: PreferredWardService,
    private preferredRosterService: PreferredRosterService,
    private hospitalOfficerPersistenceService: HospitalOfficerPersistenceService,
    private hospitalOfficerService: HospitalOfficerService
  ) {}
}
