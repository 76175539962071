import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';

import {
  authGuardService,
  redirectGuardService,
} from '@locumsnest/auth/src/lib/session-auth/services';
import { RECOVERY_PAGE_DETAIL_MAP } from '@locumsnest/auth/src/recovery/containers/recovery-page/opaque-tokens';

import { ControlAuthGuardService } from '../../../../libs/control/services/auth-guard.service';
import { AuthLayoutComponent } from './account/components/auth-layout/auth-layout.component';
import { InverseAgencyAuthGuardService } from './agency-platform/services/auth-guard-inverse.service';
import { AgencyAuthGuardService } from './agency-platform/services/auth-guard.service';
import { CommonFilterPanelComponent } from './core/containers/common-filter-panel/common-filter-panel.component';
import { LayoutContainerComponent } from './core/containers/layout-container/layout-container.component';
import { EXTERNAL_ROUTES } from './core/services';
import { CommonComponent } from './dashboard/containers/common/common.component';

function hospitalOfficerMatcherFunction(url: UrlSegment[]) {
  if (url.toString().endsWith('hospital-officer')) {
    return { consumed: url };
  }
  return null;
}

function owlversityMatcherFunction(url: UrlSegment[]) {
  if (url.toString().endsWith('owlversity')) {
    return { consumed: url };
  }
  return null;
}

function latestReleasesMatcherFunction(url: UrlSegment[]) {
  if (url.toString().endsWith('latest-releases')) {
    return { consumed: url };
  }
  return null;
}

export const routes: Routes = [
  { path: '', pathMatch: 'full', children: [], canMatch: [redirectGuardService] },
  {
    path: '',
    loadChildren: () => import('./account/account.routes').then((m) => m.routes),
  },
  {
    path: '',
    component: LayoutContainerComponent,
    canMatch: [authGuardService],
    canActivateChild: [authGuardService],
    children: [
      {
        path: 'zendesk',
        outlet: 'messaging-panel',
        loadChildren: () =>
          import(
            '../../src/app/core/containers/common-messaging-panel/common-messaging-panel.module'
          ).then((m) => m.CommonMessagingPanelModule),
      },
      {
        path: 'agency',
        canActivate: [AgencyAuthGuardService],
        loadChildren: () =>
          import('./agency-platform/agency-platform.module').then((m) => m.AgencyPlatformModule),
      },
      {
        matcher: hospitalOfficerMatcherFunction,
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () =>
          import('./hospital-officer/hospital-officer.module').then((m) => m.HospitalOfficerModule),
      },
      {
        path: 'control',
        canActivate: [ControlAuthGuardService],
        loadChildren: () =>
          import('@locumsnest/control/control.routes').then((r) => r.controlRoutes),
      },
      {
        matcher: owlversityMatcherFunction,
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () =>
          import('./owlversity/owlversity.module').then((m) => m.OwlversityModule),
      },
      {
        matcher: latestReleasesMatcherFunction,
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () =>
          import('./latest-releases/latest-releases.module').then((m) => m.LatestReleasesModule),
      },

      {
        path: 'job-listings',
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () =>
          import('./job-listing/job-listing.module').then((m) => m.JobListingModule),
      },
      {
        path: 'time-sheets',
        children: [
          {
            path: '',
            outlet: 'common-component',
            component: CommonComponent,
          },
          {
            path: '',
            outlet: 'filter-panel',
            component: CommonFilterPanelComponent,
          },
          {
            path: '',
            loadChildren: () =>
              import('./time-sheet/time-sheet.module').then((m) => m.TimeSheetModule),
          },
        ],
        canActivate: [InverseAgencyAuthGuardService],
      },
      {
        path: 'profile',
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'dashboard',
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'intelligence',
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () =>
          import('./intelligence/intelligence.module').then((m) => m.IntelligenceModule),
      },
      {
        path: 'passport',
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () => import('./passport/passport.module').then((m) => m.PassportModule),
      },
      {
        path: 'link',
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () => import('./link/link.module').then((m) => m.LinkModule),
      },
      {
        path: 'trust-onboarding',
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () =>
          import('./trust-onboarding-process/trust-onboarding-process.module').then(
            (m) => m.TrustOnboardingProcessModule,
          ),
      },
      {
        path: 'community/dashboard',
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () => import('./community/community.module').then((m) => m.CommunityModule),
      },
      {
        path: 'rota',
        canActivate: [InverseAgencyAuthGuardService],
        loadChildren: () => import('./rota/rota.module').then((m) => m.RotaModule),
      },
    ],
  },

  {
    path: '**',
    component: AuthLayoutComponent,
    children: [
      {
        path: '**',
        loadComponent: () =>
          import('./core/containers/not-found-page/not-found-page.component').then(
            (c) => c.NotFoundPageComponent,
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    { provide: EXTERNAL_ROUTES, useValue: ['/recovery'] },
    {
      provide: RECOVERY_PAGE_DETAIL_MAP,
      useValue: {
        '/recovery': {
          title: 'Password Recovery',
          description: 'Please provide your new password',
          image: 'assets/icons/tip-clipboard.svg',
        },
        '/recovery/onboarding': {
          title: 'Welcome!',
          description: 'Please provide a new password to get started',
          image: 'assets/icons/tip-clipboard.svg',
        },
        '/recovery/hospital-onboarding': {
          title: 'Welcome!',
          description: 'Please provide a new password to get started',
          image: 'assets/icons/tip-clipboard.svg',
        },
      },
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
