import { IHospitalSpecialtyEntity } from '../../interfaces/api/hospital-specialty-entity';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

export const adapter: EntityAdapter<IHospitalSpecialtyEntity> =
  createEntityAdapter<IHospitalSpecialtyEntity>({});

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedHospitalSpecialtyId: null,
});
