/// <reference types="@angular/localize" />
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserTracing, init, routingInstrumentation } from '@sentry/angular-ivy';
import moment from 'moment-timezone';

import packageInfo from '../../../package.json';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

moment.tz.setDefault('Europe/London');
const { sentry: sentryConfig } = environment;
if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

if (environment.production) {
  enableProdMode();
}
const sentryEnabled =
  sentryConfig.dsn &&
  sentryConfig.whiteList.some((origin) => window.location.href.startsWith(origin));

if (sentryEnabled) {
  init({
    release: packageInfo.version,
    dsn: sentryConfig.dsn,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new BrowserTracing({
        tracingOrigins: sentryConfig.whiteList.map((origin) => `${origin}/api`),
        routingInstrumentation,
      }),
    ],

    // beforeSend(event, hint) {
    //   // todo: evaluate and filter
    //   // const error = hint.originalException;
    //   // if (
    //   //   /Http failure response for http.*:4[0-9][1-24-7].*/.test(error.toString())
    //   //   ){
    //   //   return null;
    //   // }

    //   return event;
    // },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}
/**eslint */
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.log(err));
