import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { UpdateEntityStateMessage } from './preferred-roster.messages';
import { selectPreferredRosterTempState } from './preferred-roster.selectors';
import { UpdateEntityStatePreferredRosterSignal } from './preferred-roster.signals';

@Injectable()
export class PreferredRosterEffects {
  updateEntityStatePreferredRosterSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateEntityStatePreferredRosterSignal>(UpdateEntityStatePreferredRosterSignal.TYPE),
      concatLatestFrom(() => this.store.pipe(select(selectPreferredRosterTempState))),
      map(
        ([_, preferredRosterTempState]) =>
          new UpdateEntityStateMessage({ preferredRosterTempState })
      )
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}
