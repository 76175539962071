import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { filter, first, map, merge, mergeMap, Observable, of } from 'rxjs';

import { ofSignalType } from '@locumsnest/core/src';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';
import { BaseEffects } from '@locumsnest/core/src/lib/ngrx/effect';

import { AuthGroupService } from '../../../auth-group/+state/auth-group.service';
import { ExternalStaffingProviderService } from '../../../external-staffing-provider/+state/external-staffing-provider.service';
import { HealthCheckService } from '../../../health-check/+state/health-check.service';
import { HospitalOfficerService } from '../../../hospital-officer/+state/hospital-officer.service';
import { HospitalService } from '../../../hospital/+state/hospital.services';
import { JobListingTypeService } from '../../../job-listing-type/+state/job-listing-type.service';
import { PermissionService } from '../../../permission/+state/permission.service';
import { PreferredProfessionService } from '../../../preferred-profession/+state/preferred-profession.service';
import { SiteService } from '../../../site/+state/site.service';
import { isEphemeralOrigin, PRODUCT_CODES } from '../../constants';
import { LayoutService } from '../../services';
import { ExternalStaffingProviderOfficerService } from './../../../external-staffing-provider-officer/+state/external-staffing-provider-officer.service';
import { ProfessionService } from './../../../profession/+state/profession.service';
import { SpecialtyService } from './../../../specialty/+state/specialty.service';
import { alertSignalToMessage } from './ui.adapter';
import {
  InitializeLayoutMessage,
  UpdateShowFiltersPanelMessage,
  UpdateShowHeaderFilterInformationMessage,
  UpdateShowProductPanelMessage,
} from './ui.messages';
import { selectShowFiltersPanel, selectShowProductPanel } from './ui.selectors';
import {
  InitializeLayoutContainerSignal,
  ShowFiltersPanelSignal,
  ShowHideHeaderFilterInformationSignal,
  ShowMessagingPanelSignal,
  ShowProductPanelSignal,
  ToggleFiltersPanelSignal,
  ToggleProductPanelSignal,
} from './ui.signals';

@Injectable()
export class LayoutUiEffects extends BaseEffects {
  initializeLayoutContainerSignal$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(InitializeLayoutContainerSignal),
      mergeMap(() => {
        const optional: Observable<Action>[] = [];

        if (isEphemeralOrigin()) {
          optional.push(this.healthCheckService.fetch());
        }

        return merge(
          ...optional,
          this.permissionService.load(),
          this.loadAppDependencies(),
          this.authGroupService.fetchMy(),
          this.jobListingTypeService.load(),
          of(new InitializeLayoutMessage({})),
        );
      }),
    ),
  );

  showHideHeaderFilterInformationSignal$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(ShowHideHeaderFilterInformationSignal),
      map(() => new UpdateShowHeaderFilterInformationMessage({})),
    ),
  );

  showFiltersPanelSignal$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(ShowFiltersPanelSignal),
      mergeMap((action) => {
        const { payload } = action as ShowFiltersPanelSignal;
        return of(new UpdateShowFiltersPanelMessage({ show: payload.show }));
      }),
    ),
  );

  showMessagingPanelSignal$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(ShowMessagingPanelSignal),
      concatLatestFrom(() => this.layoutService.getShowMessagingPanel()),
      mergeMap(([_, showMessagingPanel]) => {
        if (!showMessagingPanel) {
          this.router.navigate([{ outlets: { 'messaging-panel': ['zendesk', 'requests'] } }], {
            queryParamsHandling: 'merge',
          });
        } else {
          this.router.navigate([{ outlets: { 'messaging-panel': [] } }], {
            queryParamsHandling: 'merge',
          });
        }

        return of(new UpdateShowFiltersPanelMessage({ show: false }));
      }),
    ),
  );

  showProductPanelSignal$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(ShowProductPanelSignal),
      mergeMap((action) => {
        const { payload } = action as ShowProductPanelSignal;
        return of(new UpdateShowProductPanelMessage({ show: payload.show }));
      }),
    ),
  );

  toggleFiltersPanelSignal$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(ToggleFiltersPanelSignal),
      concatLatestFrom(() => this.store.pipe(select(selectShowFiltersPanel))),
      mergeMap(([_, showFiltersPanel]) => {
        if (showFiltersPanel) {
          return merge(
            of(new UpdateShowFiltersPanelMessage({ show: false })),
            of(new UpdateShowProductPanelMessage({ show: false })),
            this.preferredProfessionService.dispatchUpdateEntitySignals(),
          );
        } else {
          return of(new UpdateShowFiltersPanelMessage({ show: true }));
        }
      }),
    ),
  );

  toggleProductPanelSignal$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofSignalType(ToggleProductPanelSignal),
      concatLatestFrom(() => this.store.pipe(select(selectShowProductPanel))),
      mergeMap(([_, showProductPanel]) => {
        if (showProductPanel) {
          return merge(
            of(new UpdateShowProductPanelMessage({ show: false })),
            of(new UpdateShowFiltersPanelMessage({ show: false })),
          );
        } else {
          return merge(
            of(new UpdateShowProductPanelMessage({ show: true })),
            of(new UpdateShowFiltersPanelMessage({ show: false })),
          );
        }
      }),
    ),
  );

  alertSignal$: Observable<Action> = createEffect(() => this.actions$.pipe(alertSignalToMessage));

  constructor(
    protected actions$: Actions,
    private store: Store,
    private hospitalService: HospitalService,
    private externalStaffingProviderOfficerService: ExternalStaffingProviderOfficerService,
    private externalStaffingProviderService: ExternalStaffingProviderService,
    private hospitalOfficerService: HospitalOfficerService,
    private permissionService: PermissionService,
    private jobListingTypeService: JobListingTypeService,
    private microAppService: MicroAppService,
    private preferredProfessionService: PreferredProfessionService,
    private authGroupService: AuthGroupService,
    private professionService: ProfessionService,
    private siteService: SiteService,
    private specialtyService: SpecialtyService,
    private healthCheckService: HealthCheckService,
    private router: Router,
    private layoutService: LayoutService,
  ) {
    super();
  }
  private loadAppDependencies() {
    return this.microAppService
      .getMicroAppCode()
      .pipe(
        filter((code) => !!code),
        first(),
      )
      .pipe(
        mergeMap((code) =>
          code === PRODUCT_CODES.AGENCY
            ? merge(
                this.externalStaffingProviderService.loadCurrent(),
                this.externalStaffingProviderOfficerService.loadCurrent(),
              )
            : merge(
                this.hospitalService.loadCurrent(),
                this.professionService.load(),
                this.siteService.load(),
                this.specialtyService.load(),
                this.hospitalOfficerService.loadCurrent(true, false),
              ),
        ),
      );
  }
}
