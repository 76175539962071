import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { NgrxFormsModule } from 'ngrx-forms';

import { ComponentsModule } from '@locumsnest/components/src';

import { reducers } from './+state';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    NgrxFormsModule,
    StoreModule.forFeature('jobListingTypes', reducers),
  ],
  declarations: [],
})
export class JobListingTypeModule {}
